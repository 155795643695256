import { createApp } from 'vue';
import { createPinia } from "pinia";
import router from "./router";
import App from './App.vue';
import '@/assets/global-styles.css';
import { initializeAuth } from "@/utils/initAuth.js";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import VueApexCharts from "vue3-apexcharts";

const app = createApp(App);

// Add a global method to toggle theme
app.config.globalProperties.$toggleTheme = function() {
  document.body.classList.toggle('dark-theme');
  document.body.classList.toggle('light-theme');
  
  // Save the current theme preference to localStorage
  const isDarkTheme = document.body.classList.contains('dark-theme');
  localStorage.setItem('darkTheme', isDarkTheme);
};

// Initialize theme based on user's previous preference or system preference
const prefersDarkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
const savedTheme = localStorage.getItem('darkTheme');
const initialTheme = savedTheme !== null ? savedTheme === 'true' : prefersDarkTheme;

document.body.classList.add(initialTheme ? 'dark-theme' : 'light-theme');

const pinia = createPinia();
app.use(pinia);
app.use(VueApexCharts);
app.use(router);

const initializeApp = async () => {
  // Wait for the authentication initialization before mounting the app
  await initializeAuth();
  
  // After authentication is initialized, mount the app
  app.mount('#app');
};

initializeApp();
