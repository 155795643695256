<template>
  <div class="adm-hero">
    <h2 class="adm-section-title">Manage Hero Sections</h2>
    <div v-if="heroStore.loading" class="adm-loading">
      <i class="bi bi-hourglass-split"></i> Loading...
    </div>
    <div v-else-if="heroStore.error" class="adm-error">
      <i class="bi bi-exclamation-triangle"></i> {{ heroStore.error }}
    </div>
    <div v-else>
      <h3 class="adm-subsection-title">Existing Hero Sections</h3>
      <div v-if="heroStore.heroSections.length" class="adm-table-responsive">
        <table class="adm-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Subtitle</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="hero in heroStore.heroSections" :key="hero.id">
              <td>{{ hero.title }}</td>
              <td>{{ hero.subtitle }}</td>
              <td>
                <button @click="editHero(hero)" class="adm-btn adm-btn-secondary adm-btn-sm">
                  <i class="bi bi-pencil"></i> Edit
                </button>
                <button @click="deleteHero(hero.id)" class="adm-btn adm-btn-danger adm-btn-sm">
                  <i class="bi bi-trash"></i> Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p v-else class="adm-no-data">No hero sections found.</p>

      <div class="adm-form-container">
        <h3 class="adm-form-title">{{ editingHero ? 'Edit Hero Section' : 'Create New Hero Section' }}</h3>
        <form @submit.prevent="saveHero" class="adm-form">
          <div class="adm-form-group">
            <label for="title" class="adm-form-label">Title:</label>
            <input id="title" v-model="currentHero.title" required class="adm-form-input">
          </div>
          <div class="adm-form-group">
            <label for="subtitle" class="adm-form-label">Subtitle:</label>
            <textarea id="subtitle" v-model="currentHero.subtitle" required class="adm-form-textarea"></textarea>
          </div>
          <div class="adm-form-group">
            <label for="cta_text" class="adm-form-label">CTA Text:</label>
            <input id="cta_text" v-model="currentHero.cta_text" required class="adm-form-input">
          </div>
          <div class="adm-form-group">
            <h4 class="adm-form-subtitle">Images:</h4>
            <div v-for="(image, index) in currentHero.images" :key="index" class="adm-image-item">
              <input v-model="image.url" placeholder="Image URL" required class="adm-form-input">
              <input v-model="image.alt" placeholder="Alt Text" required class="adm-form-input">
              <button type="button" @click="removeImage(index)" class="adm-btn adm-btn-danger adm-btn-sm">
                <i class="bi bi-trash"></i> Remove
              </button>
            </div>
            <button type="button" @click="addImage" class="adm-btn adm-btn-secondary">
              <i class="bi bi-plus-circle"></i> Add Image
            </button>
          </div>
          <div class="adm-form-actions">
            <button type="submit" class="adm-btn adm-btn-primary">
              <i class="bi" :class="editingHero ? 'bi-pencil-square' : 'bi-save'"></i>
              {{ editingHero ? 'Update' : 'Create' }} Hero Section
            </button>
            <button type="button" @click="cancelEdit" class="adm-btn adm-btn-secondary">
              <i class="bi bi-x-circle"></i> Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useHeroStore } from '@/stores/heroStore';

export default {
  name: 'AdminHero',
  setup() {
    const heroStore = useHeroStore();
    const editingHero = ref(null);
    const currentHero = ref(getEmptyHero());

    function getEmptyHero() {
      return {
        title: '',
        subtitle: '',
        cta_text: '',
        images: []
      };
    }

    onMounted(async () => {
      await heroStore.fetchHeroSections();
    });

    const addImage = () => {
      currentHero.value.images.push({ url: '', alt: '' });
    };

    const removeImage = (index) => {
      currentHero.value.images.splice(index, 1);
    };

    const editHero = (hero) => {
      editingHero.value = hero;
      currentHero.value = JSON.parse(JSON.stringify(hero));
    };

    const deleteHero = async (id) => {
      if (confirm('Are you sure you want to delete this hero section?')) {
        await heroStore.deleteHeroSection(id);
      }
    };

    const saveHero = async () => {
      if (editingHero.value) {
        await heroStore.updateHeroSection(currentHero.value);
      } else {
        await heroStore.createHeroSection(currentHero.value);
      }
      if (!heroStore.error) {
        cancelEdit();
      }
    };

    const cancelEdit = () => {
      editingHero.value = null;
      currentHero.value = getEmptyHero();
    };

    return {
      heroStore,
      currentHero,
      editingHero,
      addImage,
      removeImage,
      editHero,
      deleteHero,
      saveHero,
      cancelEdit
    };
  }
};
</script>

<style scoped>
.adm-hero {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--adm-spacing-md);
}

.adm-section-title {
  font-size: var(--adm-font-size-xl);
  margin-bottom: var(--adm-spacing-lg);
  color: var(--adm-primary-color);
  font-weight: var(--adm-font-weight-bold);
  text-align: center;
}

.adm-subsection-title {
  font-size: var(--adm-font-size-lg);
  margin-bottom: var(--adm-spacing-md);
  color: var(--adm-text-color);
  font-weight: var(--adm-font-weight-medium);
}

.adm-loading,
.adm-error,
.adm-no-data {
  padding: var(--adm-spacing-md);
  border-radius: var(--adm-border-radius);
  margin-bottom: var(--adm-spacing-md);
  font-size: var(--adm-font-size-base);
}

.adm-loading {
  background-color: var(--adm-bg-secondary);
  color: var(--adm-text-color);
}

.adm-error {
  background-color: var(--adm-danger-color);
  color: var(--adm-bg-primary);
}

.adm-no-data {
  background-color: var(--adm-bg-secondary);
  color: var(--adm-text-color-light);
}

.adm-table-responsive {
  overflow-x: auto;
}

.adm-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: var(--adm-spacing-lg);
}

.adm-table th,
.adm-table td {
  padding: var(--adm-spacing-sm);
  text-align: left;
  border-bottom: 1px solid var(--adm-border-color);
}

.adm-table th {
  background-color: var(--adm-bg-secondary);
  color: var(--adm-text-color);
  font-weight: var(--adm-font-weight-bold);
}

.adm-table tr:hover {
  background-color: var(--adm-bg-secondary);
}

.adm-form-container {
  background-color: var(--adm-bg-primary);
  padding: var(--adm-spacing-lg);
  border-radius: var(--adm-border-radius);
  box-shadow: var(--adm-shadow);
  margin-bottom: var(--adm-spacing-lg);
}

.adm-form-title {
  font-size: var(--adm-font-size-lg);
  margin-bottom: var(--adm-spacing-md);
  color: var(--adm-text-color);
  font-weight: var(--adm-font-weight-bold);
}

.adm-form {
  display: grid;
  gap: var(--adm-spacing-md);
}

.adm-form-group {
  display: flex;
  flex-direction: column;
}

.adm-form-label {
  margin-bottom: var(--adm-spacing-xs);
  font-weight: var(--adm-font-weight-medium);
  color: var(--adm-text-color);
}

.adm-form-input,
.adm-form-textarea {
  width: 100%;
  padding: var(--adm-spacing-sm);
  border: 1px solid var(--adm-border-color);
  border-radius: var(--adm-border-radius-sm);
  font-size: var(--adm-font-size-base);
  color: var(--adm-text-color);
  background-color: var(--adm-bg-primary);
  transition: var(--adm-transition);
}

.adm-form-input:focus,
.adm-form-textarea:focus {
  outline: none;
  border-color: var(--adm-primary-color);
  box-shadow: 0 0 0 2px rgba(var(--adm-primary-color-rgb), 0.2);
}

.adm-form-textarea {
  min-height: 100px;
  resize: vertical;
}

.adm-form-subtitle {
  font-size: var(--adm-font-size-base);
  margin-bottom: var(--adm-spacing-sm);
  color: var(--adm-text-color);
  font-weight: var(--adm-font-weight-medium);
}

.adm-image-item {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: var(--adm-spacing-sm);
  margin-bottom: var(--adm-spacing-sm);
}

.adm-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: var(--adm-spacing-sm);
}

.adm-btn {
  padding: var(--adm-spacing-sm) var(--adm-spacing-md);
  border: none;
  border-radius: var(--adm-border-radius-sm);
  cursor: pointer;
  font-size: var(--adm-font-size-base);
  font-weight: var(--adm-font-weight-medium);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: var(--adm-transition);
}

.adm-btn i {
  margin-right: var(--adm-spacing-xs);
}

.adm-btn-primary {
  background-color: var(--adm-primary-color);
  color: var(--adm-bg-primary);
}

.adm-btn-primary:hover,
.adm-btn-primary:focus {
  background-color: var(--adm-primary-color-dark);
}

.adm-btn-secondary {
  background-color: var(--adm-secondary-color);
  color: var(--adm-bg-primary);
}

.adm-btn-secondary:hover,
.adm-btn-secondary:focus {
  background-color: var(--adm-secondary-color-dark);
}

.adm-btn-danger {
  background-color: var(--adm-danger-color);
  color: var(--adm-bg-primary);
}

.adm-btn-danger:hover,
.adm-btn-danger:focus {
  background-color: var(--adm-danger-color-dark);
}

.adm-btn-sm {
  padding: var(--adm-spacing-xs) var(--adm-spacing-sm);
  font-size: var(--adm-font-size-sm);
}

@media (max-width: 768px) {
  .adm-image-item {
    grid-template-columns: 1fr;
  }

  .adm-form-actions {
    flex-direction: column;
  }

  .adm-btn {
    width: 100%;
    margin-bottom: var(--adm-spacing-sm);
  }
}
</style>