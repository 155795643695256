// stores/heroStore.js
import { defineStore } from 'pinia';
import api from '@/axios/api.js';

export const useHeroStore = defineStore('hero', {
  state: () => ({
    heroSections: [],
    loading: false,
    error: null
  }),
  actions: {
    async fetchHeroSections() {
      this.loading = true;
      try {
        const response = await api.get('/hero');
        this.heroSections = response.data;
        this.error = null;
      } catch (error) {
        console.error('Error fetching hero sections:', error);
        this.error = 'Failed to fetch hero sections';
      } finally {
        this.loading = false;
      }
    },
    async createHeroSection(heroData) {
      this.loading = true;
      try {
        const response = await api.post('/hero', heroData);
        this.heroSections.push(response.data);
        this.error = null;
      } catch (error) {
        console.error('Error creating hero section:', error);
        this.error = 'Failed to create hero section';
      } finally {
        this.loading = false;
      }
    },
    async updateHeroSection(heroData) {
      this.loading = true;
      try {
        const response = await api.put(`/hero/${heroData.id}`, heroData);
        const index = this.heroSections.findIndex(h => h.id === heroData.id);
        if (index !== -1) {
          this.heroSections[index] = response.data;
        }
        this.error = null;
      } catch (error) {
        console.error('Error updating hero section:', error);
        this.error = 'Failed to update hero section';
      } finally {
        this.loading = false;
      }
    },
    async deleteHeroSection(id) {
      this.loading = true;
      try {
        await api.delete(`/hero/${id}`);
        this.heroSections = this.heroSections.filter(h => h.id !== id);
        this.error = null;
      } catch (error) {
        console.error('Error deleting hero section:', error);
        this.error = 'Failed to delete hero section';
      } finally {
        this.loading = false;
      }
    }
  }
});