<template>
  <section class="hero-section" aria-label="Hero Slider">
    <Carousel v-if="heroStore.heroSections.length" 
              :items-to-show="1" 
              :wrap-around="true" 
              :autoplay="5000" 
              :transition="500"
              :mouseDrag="false"
              :touchDrag="true"
              :breakpoints="carouselBreakpoints">
      <Slide v-for="hero in heroStore.heroSections" :key="hero.id">
        <div class="slide">
          <img :src="hero.images[0]?.url" 
               :alt="hero.images[0]?.alt" 
               class="hero-image"
               loading="lazy"
               decoding="async">
          <div class="hero-content">
            <h2 class="hero-title">{{ hero.title }}</h2>
            <p class="hero-subtitle">{{ hero.subtitle }}</p>
            <!-- <button class="cta-button" @click="handleCTA(hero)">{{ hero.cta_text }}</button> -->
          </div>
        </div>
      </Slide>

      <template #addons>
        <Navigation>
          <template #next>
            <button class="control-button next" aria-label="Next slide">&gt;</button>
          </template>
          <template #prev>
            <button class="control-button prev" aria-label="Previous slide">&lt;</button>
          </template>
        </Navigation>
        <Pagination />
      </template>
    </Carousel>
  </section>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useHeroStore } from '@/stores/heroStore';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  name: 'HeroSection',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  setup() {
    const heroStore = useHeroStore();

    const carouselBreakpoints = ref({
      768: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      1024: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
    });

    const handleCTA = (hero) => {
      // Implement CTA action here
      console.log('CTA clicked for:', hero.title);
    };

    onMounted(async () => {
      await heroStore.fetchHeroSections();
    });

    return {
      heroStore,
      carouselBreakpoints,
      handleCTA,
    };
  }
}
</script>

<style scoped>
.hero-section {
  position: relative;
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  max-height: 800px;
  overflow: hidden;
  margin-top: 50px;

}

.slide {
  position: relative;
  width: 100%;
  height: 100%;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  z-index: 10;
  width: 90%;
  max-width: 600px;
}

.hero-title {
  font-size: clamp(2rem, 5vw, 3.5rem);
  margin-bottom: 1rem;
  line-height: 1.2;
}

.hero-subtitle {
  font-size: clamp(1rem, 3vw, 1.2rem);
  margin-bottom: 2rem;
  line-height: 1.5;
}

.cta-button {
  padding: 12px 24px;
  font-size: clamp(0.9rem, 2vw, 1.1rem);
  background-color: var(--light-accent-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.cta-button:hover {
  background-color: var(--light-hover-color);
  transform: translateY(-2px);
}

.cta-button:focus {
  outline: 2px solid white;
  outline-offset: 2px;
}

.control-button {
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  color: white;
  font-size: 1.5rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-button:hover {
  background-color: rgba(255, 255, 255, 0.8);
  transform: scale(1.1);
}

.control-button:focus {
  outline: 2px solid white;
  outline-offset: 2px;
}

/* Carousel custom styles */
:deep(.carousel__slide) {
  padding: 0;
}

:deep(.carousel__pagination) {
  padding: 0;
}

:deep(.carousel__pagination-button::after) {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
}

:deep(.carousel__pagination-button:hover::after),
:deep(.carousel__pagination-button--active::after) {
  background-color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero-section {
    height: 50vh;
  }

  .control-button {
    font-size: 1.2rem;
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .hero-section {
    height: 35vh;
  }

  .hero-content {
    width: 95%;
  }
}

/* Accessibility and print styles */
@media (prefers-reduced-motion: reduce) {
  .cta-button,
  .control-button {
    transition: none;
  }
}

@media print {
  .hero-section {
    height: auto;
    page-break-inside: avoid;
  }

  .hero-image {
    max-height: 300px;
  }

  .control-button,
  :deep(.carousel__pagination) {
    display: none;
  }
}
</style>