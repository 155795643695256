<template>
  <div class="auth-container">
    <div class="auth-card">
      <h2 class="form-title gradient-text">Welcome Back</h2>
      <transition name="fade" mode="out-in">
        <form @submit.prevent="handleLogin" class="auth-form" aria-label="Login Form">
          <div class="form-group">
            <label for="login-email">Email</label>
            <input type="email" id="login-email" v-model="loginForm.email" required aria-required="true" />
          </div>
          <div class="form-group">
            <label for="login-password">Password</label>
            <input type="password" id="login-password" v-model="loginForm.password" required aria-required="true" />
          </div>
          <button type="submit" class="btn sparkle" :disabled="authStore.isLoading">
            {{ authStore.isLoading ? 'Loading...' : 'Log In' }}
          </button>
          <p class="forgot-password">
            <a href="#" @click.prevent="forgotPassword">Forgot Password?</a>
          </p>
        </form>
      </transition>

      <div v-if="authStore.error" class="error-message" role="alert">
        {{ authStore.error }}
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from 'vue';
import { useAuthStore } from '@/stores/authStore';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();

    const loginForm = reactive({
      email: '',
      password: ''
    });

    // Watch for error changes to clear it after some time
    watch(() => authStore.error, (newValue) => {
      if (newValue) {
        setTimeout(() => {
          authStore.clearError();
        }, 5000);
      }
    });

    const validateForm = () => {
      if (!loginForm.email || !loginForm.password) {
        authStore.error = 'Please fill in all fields';
        return false;
      }
      return true;
    };

    const handleLogin = async () => {
      if (!validateForm()) return;

      await authStore.login(loginForm.email, loginForm.password);
      if (authStore.isAuthenticated) {
        router.push('/studio-admin'); 
      }
    };

    const forgotPassword = async () => {
      if (!loginForm.email) {
        authStore.error = 'Please enter your email address';
        return;
      }

      await authStore.forgotPassword(loginForm.email);
      alert('Password reset instructions have been sent to your email.');
    };

    return {
      loginForm,
      authStore,
      handleLogin,
      forgotPassword
    };
  }
};
</script>

<style scoped>
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.auth-card {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.form-title {
  text-align: center;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn {
  width: 100%;
  padding: 0.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn:disabled {
  background-color: #aaa;
}

.btn:hover:not(:disabled) {
  background-color: #0056b3;
}

.forgot-password {
  text-align: center;
  margin-top: 1rem;
}

.error-message {
  color: red;
  margin-top: 1rem;
  text-align: center;
}
</style>
