// stores/imageStore.js
import { defineStore } from 'pinia';
import api from '@/axios/api.js';

export const useImageStore = defineStore('image', {
  state: () => ({
    images: [],
    loading: false,
    error: null
  }),
  actions: {
    async fetchImages() {
      this.loading = true;
      try {
        const response = await api.get('/admin/images');
        this.images = response.data;
        this.error = null;
      } catch (error) {
        console.error('Error fetching images:', error);
        this.error = 'Failed to fetch images';
      } finally {
        this.loading = false;
      }
    },
    async uploadImage(file) {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append('image', file);
        const response = await api.post('/admin/images', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.images.push(response.data);
        this.error = null;
      } catch (error) {
        console.error('Error uploading image:', error);
        this.error = 'Failed to upload image';
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async updateImage(image) {
      this.loading = true;
      try {
        const response = await api.put('/admin/images', image);
        const index = this.images.findIndex(i => i.id === image.id && i.source === image.source);
        if (index !== -1) {
          this.images[index] = { ...this.images[index], ...response.data };
        }
        this.error = null;
      } catch (error) {
        console.error('Error updating image:', error);
        this.error = 'Failed to update image';
      } finally {
        this.loading = false;
      }
    },
    async deleteImage(image) {
      this.loading = true;
      try {
        await api.delete('/admin/images', { data: { id: image.id, source: image.source } });
        this.images = this.images.filter(i => !(i.id === image.id && i.source === image.source));
        this.error = null;
      } catch (error) {
        console.error('Error deleting image:', error);
        this.error = 'Failed to delete image';
      } finally {
        this.loading = false;
      }
    },
    async bulkDeleteImages(images) {
      this.loading = true;
      try {
        const imagesToDelete = images.map(img => ({ id: img.id, source: img.source }));
        await api.post('/admin/images/bulk-delete', { images: imagesToDelete });
        this.images = this.images.filter(img => 
          !imagesToDelete.some(delImg => delImg.id === img.id && delImg.source === img.source)
        );
        this.error = null;
      } catch (error) {
        console.error('Error bulk deleting images:', error);
        this.error = 'Failed to bulk delete images';
      } finally {
        this.loading = false;
      }
    }
  }
});