import { defineStore } from 'pinia';
import api from '@/axios/api.js';

export const useDonationStore = defineStore('donation', {
  state: () => ({
    loading: false,
    error: null
  }),
  actions: {
    async verifyPayment(paymentData) {
      this.loading = true;
      this.error = null;
      try {
        const response = await api.post('/donations/verify', {
          ...paymentData,
          isReturningDonor: paymentData.isReturningDonor
        });
        
        if (response.data.success) {
          // You might want to update some state here based on the response
          // For example, updating donor information or donation history
          return response.data;
        } else {
          throw new Error(response.data.message || 'Verification failed');
        }
      } catch (error) {
        console.error('Error verifying payment:', error);
        this.error = error.response?.data?.error || error.message || 'Failed to verify payment';
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async checkExistingDonor(email) {
      try {
        const response = await api.get(`/donors/check?email=${encodeURIComponent(email)}`)
        return response.data.donor
      } catch (error) {
        console.error('Error checking existing donor:', error)
        throw error
      }
    },
  }
});