<template>
  <div>
    <router-view :isDarkTheme="isDarkTheme" @toggle-theme="toggleTheme" />
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'

export default {
  name: 'App',
  setup() {
    const isDarkTheme = ref(false)

    const toggleTheme = () => {
      isDarkTheme.value = !isDarkTheme.value
      applyTheme()
    }

    const applyTheme = () => {
      if (isDarkTheme.value) {
        document.body.classList.add('dark-theme')
      } else {
        document.body.classList.remove('dark-theme')
      }
    }

    const loadTheme = () => {
      const savedTheme = localStorage.getItem('isDarkTheme')
      if (savedTheme !== null) {
        isDarkTheme.value = JSON.parse(savedTheme)
      } else {
        isDarkTheme.value = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      }
      applyTheme()
    }

    onMounted(() => {
      loadTheme()
    })

    watch(isDarkTheme, (newValue) => {
      localStorage.setItem('isDarkTheme', JSON.stringify(newValue))
      applyTheme()
    })

    return {
      isDarkTheme,
      toggleTheme
    }
  }
}
</script>




