<template>
  <div class="adm-upcoming-projects">
    <h2 class="adm-section-title">Manage Upcoming Projects</h2>
    <div v-if="upcomingProjectsStore.loading" class="adm-loading">
      <i class="bi bi-hourglass-split"></i> Loading...
    </div>
    <div v-else-if="upcomingProjectsStore.error" class="adm-error">
      <i class="bi bi-exclamation-triangle"></i> {{ upcomingProjectsStore.error }}
    </div>
    <div v-else>
      <h3 class="adm-subsection-title">Existing Upcoming Projects</h3>
      <div v-if="upcomingProjectsStore.upcomingProjects.length" class="adm-table-responsive">
        <table class="adm-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Funding Needed</th>
              <th>Funding Received</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="project in upcomingProjectsStore.upcomingProjects" :key="project.id">
              <td data-label="Name">{{ project.name }}</td>
              <td data-label="Description">{{ project.description }}</td>
              <td data-label="Funding Needed">${{ (project.funding_needed || 0).toLocaleString() }}</td>
              <td data-label="Funding Received">${{ (project.funding_received || 0).toLocaleString() }}</td>
              <td data-label="Actions">
                <button @click="editProject(project)" class="adm-btn adm-btn-secondary adm-btn-sm">
                  <i class="bi bi-pencil"></i> Edit
                </button>
                <button @click="deleteProject(project.id)" class="adm-btn adm-btn-danger adm-btn-sm">
                  <i class="bi bi-trash"></i> Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p v-else class="adm-no-data">No upcoming projects found.</p>

      <div class="adm-form-container">
        <h3 class="adm-form-title">{{ editingProject ? 'Edit Upcoming Project' : 'Create New Upcoming Project' }}</h3>
        <form @submit.prevent="saveProject" class="adm-form">
          <div class="adm-form-group">
            <label for="name" class="adm-form-label">Name:</label>
            <input id="name" v-model="currentProject.name" required class="adm-form-input">
          </div>
          <div class="adm-form-group">
            <label for="description" class="adm-form-label">Description:</label>
            <textarea id="description" v-model="currentProject.description" required class="adm-form-textarea"></textarea>
          </div>
          <div class="adm-form-group">
            <label for="funding_needed" class="adm-form-label">Funding Needed:</label>
            <input id="funding_needed" v-model.number="currentProject.funding_needed" type="number" required class="adm-form-input">
          </div>
          <div class="adm-form-group">
            <label for="funding_received" class="adm-form-label">Funding Received:</label>
            <input id="funding_received" v-model.number="currentProject.funding_received" type="number" required class="adm-form-input">
          </div>
          <div class="adm-form-actions">
            <button type="submit" class="adm-btn adm-btn-primary">
              <i class="bi" :class="editingProject ? 'bi-pencil-square' : 'bi-save'"></i>
              {{ editingProject ? 'Update' : 'Create' }} Project
            </button>
            <button type="button" @click="cancelEdit" class="adm-btn adm-btn-secondary">
              <i class="bi bi-x-circle"></i> Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useUpcomingProjectsStore } from '@/stores/upcomingProjectsStore';

export default {
  name: 'AdminUpcomingProjects',
  setup() {
    const upcomingProjectsStore = useUpcomingProjectsStore();
    const editingProject = ref(null);
    const currentProject = ref(getEmptyProject());

    function getEmptyProject() {
      return {
        name: '',
        description: '',
        funding_needed: 0,
        funding_received: 0
      };
    }
    onMounted(() => {
      upcomingProjectsStore.fetchUpcomingProjects();
    });

    const editProject = (project) => {
      editingProject.value = project;
      currentProject.value = { ...project };
    };

    const deleteProject = async (id) => {
      if (confirm('Are you sure you want to delete this project?')) {
        await upcomingProjectsStore.deleteUpcomingProject(id);
      }
    };

    const saveProject = async () => {
      if (editingProject.value) {
        await upcomingProjectsStore.updateUpcomingProject(currentProject.value);
      } else {
        await upcomingProjectsStore.createUpcomingProject(currentProject.value);
      }
      if (!upcomingProjectsStore.error) {
        cancelEdit();
      }
    };

    const cancelEdit = () => {
      editingProject.value = null;
      currentProject.value = getEmptyProject();
    };

    return {
      upcomingProjectsStore,
      currentProject,
      editingProject,
      editProject,
      deleteProject,
      saveProject,
      cancelEdit
    };
  }
};
</script>
<style scoped>
.adm-upcoming-projects {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--adm-spacing-md);
}

.adm-section-title {
  font-size: var(--adm-font-size-xl);
  margin-bottom: var(--adm-spacing-lg);
  color: var(--adm-primary-color);
  font-weight: var(--adm-font-weight-bold);
  text-align: center;
}

.adm-subsection-title {
  font-size: var(--adm-font-size-lg);
  margin-bottom: var(--adm-spacing-md);
  color: var(--adm-text-color);
  font-weight: var(--adm-font-weight-medium);
}

.adm-loading,
.adm-error,
.adm-no-data {
  padding: var(--adm-spacing-md);
  border-radius: var(--adm-border-radius);
  margin-bottom: var(--adm-spacing-md);
  font-size: var(--adm-font-size-base);
}

.adm-loading {
  background-color: var(--adm-bg-secondary);
  color: var(--adm-text-color);
}

.adm-error {
  background-color: var(--adm-danger-color);
  color: var(--adm-bg-primary);
}

.adm-no-data {
  background-color: var(--adm-bg-secondary);
  color: var(--adm-text-color-light);
}

.adm-table-responsive {
  overflow-x: auto;
}

.adm-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: var(--adm-spacing-lg);
}

.adm-table th,
.adm-table td {
  padding: var(--adm-spacing-sm);
  text-align: left;
  border-bottom: 1px solid var(--adm-border-color);
}

.adm-table th {
  background-color: var(--adm-bg-secondary);
  color: var(--adm-text-color);
  font-weight: var(--adm-font-weight-bold);
}

.adm-table tr:hover {
  background-color: var(--adm-bg-secondary);
}

.adm-form-container {
  background-color: var(--adm-bg-primary);
  padding: var(--adm-spacing-lg);
  border-radius: var(--adm-border-radius);
  box-shadow: var(--adm-shadow);
  margin-top: var(--adm-spacing-lg);
}

.adm-form-title {
  font-size: var(--adm-font-size-lg);
  margin-bottom: var(--adm-spacing-md);
  color: var(--adm-text-color);
  font-weight: var(--adm-font-weight-bold);
}

.adm-form {
  display: grid;
  gap: var(--adm-spacing-md);
}

.adm-form-group {
  display: flex;
  flex-direction: column;
}

.adm-form-label {
  margin-bottom: var(--adm-spacing-xs);
  font-weight: var(--adm-font-weight-medium);
  color: var(--adm-text-color);
}

.adm-form-input,
.adm-form-textarea {
  width: 100%;
  padding: var(--adm-spacing-sm);
  border: 1px solid var(--adm-border-color);
  border-radius: var(--adm-border-radius-sm);
  font-size: var(--adm-font-size-base);
  color: var(--adm-text-color);
  background-color: var(--adm-bg-primary);
  transition: var(--adm-transition);
}

.adm-form-input:focus,
.adm-form-textarea:focus {
  outline: none;
  border-color: var(--adm-primary-color);
  box-shadow: 0 0 0 2px rgba(var(--adm-primary-color-rgb), 0.2);
}

.adm-form-textarea {
  min-height: 100px;
  resize: vertical;
}

.adm-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: var(--adm-spacing-sm);
}

.adm-btn {
  padding: var(--adm-spacing-sm) var(--adm-spacing-md);
  border: none;
  border-radius: var(--adm-border-radius-sm);
  cursor: pointer;
  font-size: var(--adm-font-size-base);
  font-weight: var(--adm-font-weight-medium);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: var(--adm-transition);
}

.adm-btn i {
  margin-right: var(--adm-spacing-xs);
}

.adm-btn-primary {
  background-color: var(--adm-primary-color);
  color: var(--adm-bg-primary);
}

.adm-btn-primary:hover,
.adm-btn-primary:focus {
  background-color: var(--adm-primary-color-dark);
}

.adm-btn-secondary {
  background-color: var(--adm-secondary-color);
  color: var(--adm-bg-primary);
}

.adm-btn-secondary:hover,
.adm-btn-secondary:focus {
  background-color: var(--adm-secondary-color-dark);
}

.adm-btn-danger {
  background-color: var(--adm-danger-color);
  color: var(--adm-bg-primary);
}

.adm-btn-danger:hover,
.adm-btn-danger:focus {
  background-color: var(--adm-danger-color-dark);
}

.adm-btn-sm {
  padding: var(--adm-spacing-xs) var(--adm-spacing-sm);
  font-size: var(--adm-font-size-sm);
}

@media screen and (max-width: 768px) {
  .adm-table {
    border: 0;
  }

  .adm-table caption {
    font-size: var(--adm-font-size-lg);
  }
  
  .adm-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .adm-table tr {
    border-bottom: 3px solid var(--adm-border-color);
    display: block;
    margin-bottom: var(--adm-spacing-md);
  }
  
  .adm-table td {
    border-bottom: 1px solid var(--adm-border-color);
    display: block;
    font-size: var(--adm-font-size-sm);
    text-align: right;
  }
  
  .adm-table td::before {
    content: attr(data-label);
    float: left;
    font-weight: var(--adm-font-weight-bold);
    text-transform: uppercase;
  }
  
  .adm-table td:last-child {
    border-bottom: 0;
  }

  .adm-form-actions {
    flex-direction: column;
  }

  .adm-btn {
    width: 100%;
    margin-bottom: var(--adm-spacing-sm);
  }

  .adm-section-title,
  .adm-subsection-title,
  .adm-form-title {
    font-size: var(--adm-font-size-lg);
  }

  .adm-form-container {
    padding: var(--adm-spacing-md);
  }

  .adm-form-input,
  .adm-form-textarea {
    font-size: var(--adm-font-size-base); /* Prevents zoom on focus in iOS */
  }
}
</style>