<template>
  <div class="adm-dashboard">
    <!-- Sidebar -->
    <nav id="adm-sidebar" :class="{ 'adm-collapsed': sidebarCollapsed, 'adm-mobile-open': isMobile && sidebarOpen }">
      <div class="adm-sidebar-header">
        <h2 class="adm-company-name" v-if="!sidebarCollapsed">Admin Panel</h2>
        <button v-if="isMobile" class="adm-btn adm-btn-icon adm-sidebar-close" @click="toggleSidebar">
          <i class="bi bi-x-lg"></i>
        </button>
      </div>
      <ul class="adm-nav adm-flex-column">
        <li class="adm-nav-item" v-for="section in sections" :key="section.id">
          <a class="adm-nav-link" href="#" @click="setActiveSection(section.id)"
            :class="{ 'adm-active': activeSection === section.id }">
            <i :class="section.icon"></i>
            <span class="adm-nav-label">{{ section.name }}</span>
          </a>
        </li>
      </ul>
      <div class="adm-sidebar-footer" v-if="!sidebarCollapsed">
        <p>&copy; {{ currentYear }} {{ companyName }}</p>
      </div>
    </nav>

    <!-- Main content wrapper -->
    <div class="adm-main-wrapper" :class="{ 'adm-sidebar-collapsed': sidebarCollapsed }">
      <!-- Top bar -->
      <nav class="adm-top-bar">
        <button class="adm-btn adm-btn-icon adm-sidebar-toggle" @click="toggleSidebar">
          <i class="bi" :class="sidebarOpen ? 'bi-x-lg' : 'bi-list'"></i>
        </button>
        <h1 class="adm-page-title adm-gradient-text">{{ activeSectionName }}</h1>
        <div class="adm-top-bar-actions">
          <div class="adm-user-menu">
            <div class="adm-dropdown">
              <button class="adm-btn adm-btn-outline-primary adm-dropdown-toggle" type="button" id="adm-userDropdown"
                @click="toggleUserDropdown">
                <img :src="authStore.user?.avatar || 'default-avatar.png'" alt="User Avatar" class="adm-user-avatar" />
                <span class="adm-user-name">{{ authStore.user?.name }}</span>
                <i class="bi bi-chevron-down"></i>
              </button>
              <ul class="adm-dropdown-menu" :class="{ 'adm-show': isUserDropdownOpen }">
                <li><a class="adm-dropdown-item" href="#" @click="setActiveSection('profile')">
                    <i class="bi bi-person"></i> User Profile
                  </a></li>
                <li><a class="adm-dropdown-item" href="#" @click="setActiveSection('mainOverview')">
                    <i class="bi bi-gear"></i> Dashboard
                  </a></li>
                <li>
                  <hr class="adm-dropdown-divider">
                </li>
                <li><a class="adm-dropdown-item" href="#" @click="logout">
                    <i class="bi bi-box-arrow-right"></i> Logout
                  </a></li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <!-- Scrollable content area -->
      <div class="adm-content-wrapper">
        <div class="adm-content-area">
          <component :is="activeComponent" @navigate="handleNavigation"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { adminSections } from '@/config/adminSections';
import { useAuthStore } from '@/stores/authStore';
import { useFooterStore } from '@/stores/footerStore';
import { useRouter } from 'vue-router';
import AdminProfile from '@/studio/userProfile.vue';
import AboutUs from '@/studio/AboutUs.vue';
import AdminFooter from '@/studio/adminFooter.vue';
import HeroSliderAdmin from '@/studio/HeroSliderAdmin.vue';
import ProjectSlider from '@/studio/ProjectSlider.vue';
import upcomingProjects from '@/studio/upcomingProjects.vue';
import allMedia from '@/studio/allMedia.vue';
import AdminDonationSettings from '@/studio/AdminDonationSettings.vue';
import donorsManagement from '@/studio/donorsManagement.vue';
import mainDashboard from '@/studio/mainOverview.vue';

const router = useRouter();
const authStore = useAuthStore();
const footerStore = useFooterStore();
const sidebarCollapsed = ref(false);
const sidebarOpen = ref(false);
const isMobile = ref(false);
const activeSection = ref('overview');
const isUserDropdownOpen = ref(false);

const sections = ref(adminSections);

const companyName = computed(() => footerStore.footerData?.ngo_name || 'Your Company');
const currentYear = computed(() => new Date().getFullYear());

const activeSectionName = computed(() => {
  const section = sections.value.find(section => section.id === activeSection.value);
  return section ? section.name : 'Unknown Section';
});

const activeComponent = computed(() => {
  const componentMap = {
    profile: AdminProfile,
    aboutUs: AboutUs,
    adminFooter: AdminFooter,
    HeroSliderAdmin: HeroSliderAdmin,
    ProjectSlider: ProjectSlider,
    upcomingProjects: upcomingProjects,
    allMedia: allMedia,
    AdminDonationSettings: AdminDonationSettings,
    donorsManagement: donorsManagement,
    mainOverview: mainDashboard,
  };
  return componentMap[activeSection.value] || mainDashboard;
});

const toggleSidebar = () => {
  if (isMobile.value) {
    sidebarOpen.value = !sidebarOpen.value;
  } else {
    sidebarCollapsed.value = !sidebarCollapsed.value;
  }
  saveStateToLocalStorage();
};

const setActiveSection = (section) => {
  if (sections.value.some(s => s.id === section)) {
    activeSection.value = section;
    if (isMobile.value) {
      sidebarOpen.value = false;
    }
    isUserDropdownOpen.value = false;
    saveStateToLocalStorage();
  } else {
    console.error(`Invalid section: ${section}`);
  }
};

const handleNavigation = (section) => {
  setActiveSection(section);
};

const logout = () => {
  authStore.logout();
  router.push('/');
};

const toggleUserDropdown = () => {
  isUserDropdownOpen.value = !isUserDropdownOpen.value;
};

const checkScreenSize = () => {
  isMobile.value = window.innerWidth < 768;
  if (isMobile.value) {
    sidebarCollapsed.value = false;
    sidebarOpen.value = false;
  } else {
    sidebarCollapsed.value = window.innerWidth < 992;
    sidebarOpen.value = true;
  }
  saveStateToLocalStorage();
};

const saveStateToLocalStorage = () => {
  localStorage.setItem('userDashboardState', JSON.stringify({
    sidebarCollapsed: sidebarCollapsed.value,
    activeSection: activeSection.value,
    isMobile: isMobile.value,
    sidebarOpen: sidebarOpen.value,
  }));
};

const loadStateFromLocalStorage = () => {
  const savedState = localStorage.getItem('userDashboardState');
  if (savedState) {
    const state = JSON.parse(savedState);
    sidebarCollapsed.value = state.sidebarCollapsed;
    isMobile.value = state.isMobile;
    sidebarOpen.value = state.sidebarOpen;
    if (sections.value.some(s => s.id === state.activeSection)) {
      activeSection.value = state.activeSection;
    } else {
      activeSection.value = 'overview';
    }
  } else {
    checkScreenSize();
  }
};

onMounted(async () => {
  loadStateFromLocalStorage();
  window.addEventListener('resize', checkScreenSize);
  authStore.initializeAuth(); // Ensure user data is loaded
  await footerStore.fetchFooterData(); // Fetch footer data
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkScreenSize);
});

// Close user dropdown when clicking outside
const handleClickOutside = (event) => {
  if (!event.target.closest('.adm-user-menu')) {
    isUserDropdownOpen.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style>
:root {
  --adm-primary-color: #007bff;
  --adm-primary-color-dark: #0056b3;
  --adm-primary-color-rgb: 0, 123, 255;
  --adm-secondary-color: #6c757d;
  --adm-secondary-color-dark: #5a6268;
  --adm-danger-color: #dc3545;
  --adm-danger-color-dark: #c82333;
  --adm-bg-primary: #ffffff;
  --adm-bg-secondary: #f8f9fa;
  --adm-text-color: #333333;
  --adm-text-color-light: #6c757d;
  --adm-border-color: #dee2e6;
  --adm-border-radius: 8px;
  --adm-border-radius-sm: 4px;
  --adm-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --adm-transition: all 0.3s ease;
  --adm-spacing-xs: 4px;
  --adm-spacing-sm: 8px;
  --adm-spacing-md: 16px;
  --adm-spacing-lg: 24px;
  --adm-font-size-sm: 12px;
  --adm-font-size-base: 14px;
  --adm-font-size-lg: 18px;
  --adm-font-size-xl: 24px;
  --adm-font-weight-normal: 400;
  --adm-font-weight-medium: 500;
  --adm-font-weight-bold: 700;
  --adm-sidebar-width: 250px;
  --adm-sidebar-collapsed-width: 70px;
  --adm-topbar-height: 60px;
}

/* Global Styles */
.adm-dashboard {
  font-family: 'Roboto', sans-serif;
  background-color: var(--adm-bg-primary);
  color: var(--adm-text-color);
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.adm-flex {
  display: flex;
}

.adm-flex-column {
  flex-direction: column;
}

/* Sidebar Styles */
#adm-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: var(--adm-sidebar-width);
  background-color: var(--adm-primary-color);
  color: var(--adm-bg-primary);
  transition: var(--adm-transition);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

#adm-sidebar.adm-collapsed {
  width: var(--adm-sidebar-collapsed-width);
}

.adm-sidebar-header {
  padding: var(--adm-spacing-md);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adm-company-name {
  font-size: var(--adm-font-size-lg);
  margin: 0;
}

.adm-sidebar-close {
  display: none;
  color: var(--adm-bg-primary);
  background: none;
  border: none;
  font-size: var(--adm-font-size-xl);
  cursor: pointer;
}

.adm-nav-item {
  margin-bottom: var(--adm-spacing-xs);
}

.adm-nav-link {
  color: var(--adm-bg-primary);
  padding: var(--adm-spacing-sm) var(--adm-spacing-md);
  transition: var(--adm-transition);
  text-decoration: none;
  display: flex;
  align-items: center;
}

.adm-nav-link:hover,
.adm-nav-link.adm-active {
  background-color: var(--adm-primary-color-dark);
}

.adm-nav-link i {
  margin-right: var(--adm-spacing-sm);
  font-size: var(--adm-font-size-lg);
}

#adm-sidebar.adm-collapsed .adm-nav-label,
#adm-sidebar.adm-collapsed .adm-company-name,
#adm-sidebar.adm-collapsed .adm-sidebar-footer {
  display: none;
}

.adm-sidebar-footer {
  margin-top: auto;
  padding: var(--adm-spacing-md);
  font-size: var(--adm-font-size-sm);
  text-align: center;
}

/* Main Content Styles */
.adm-main-wrapper {
  flex-grow: 1;
  margin-left: var(--adm-sidebar-width);
  display: flex;
  flex-direction: column;
  height: 100vh;
  transition: var(--adm-transition);
}

.adm-main-wrapper.adm-sidebar-collapsed {
  margin-left: var(--adm-sidebar-collapsed-width);
}

/* Top Bar Styles */
.adm-top-bar {
  height: var(--adm-topbar-height);
  background-color: var(--adm-bg-primary);
  box-shadow: var(--adm-shadow);
  display: flex;
  align-items: center;
  padding: 0 var(--adm-spacing-md);
  position: sticky;
  top: 0;
  z-index: 999;
}

.adm-btn-icon {
  background: none;
  border: none;
  font-size: var(--adm-font-size-xl);
  color: var(--adm-text-color);
  cursor: pointer;
}

.adm-page-title {
  margin: 0 var(--adm-spacing-md);
  font-size: var(--adm-font-size-xl);
  font-weight: var(--adm-font-weight-bold);
}

.adm-gradient-text {
  background: linear-gradient(45deg, var(--adm-primary-color), var(--adm-primary-color-dark));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.adm-top-bar-actions {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.adm-user-menu {
  margin-left: var(--adm-spacing-md);
}

/* Dropdown Styles */
.adm-dropdown {
  position: relative;
}

.adm-dropdown-toggle {
  background-color: transparent;
  color: var(--adm-text-color);
  border: 1px solid var(--adm-border-color);
  padding: var(--adm-spacing-sm) var(--adm-spacing-md);
  border-radius: var(--adm-border-radius);
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: var(--adm-transition);
}

.adm-dropdown-toggle:hover {
  background-color: var(--adm-bg-secondary);
}

.adm-user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: var(--adm-spacing-sm);
}

.adm-user-name {
  margin-right: var(--adm-spacing-sm);
}

.adm-dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: var(--adm-bg-primary);
  box-shadow: var(--adm-shadow);
  border-radius: var(--adm-border-radius);
  display: none;
  z-index: 1000;
  min-width: 200px;
}

.adm-dropdown-menu.adm-show {
  display: block;
}

.adm-dropdown-item {
  display: flex;
  align-items: center;
  padding: var(--adm-spacing-sm) var(--adm-spacing-md);
  color: var(--adm-text-color);
  text-decoration: none;
  transition: var(--adm-transition);
}

.adm-dropdown-item:hover {
  background-color: var(--adm-bg-secondary);
}

.adm-dropdown-item i {
  margin-right: var(--adm-spacing-sm);
}

.adm-dropdown-divider {
  border-top: 1px solid var(--adm-border-color);
  margin: var(--adm-spacing-xs) 0;
}

/* Content Area Styles */
.adm-content-wrapper {
  flex-grow: 1;
  overflow-y: auto;
  padding: var(--adm-spacing-md);
}

.adm-content-area {
  background-color: var(--adm-bg-secondary);
  border-radius: var(--adm-border-radius);
  padding: var(--adm-spacing-md);
  box-shadow: var(--adm-shadow);
  min-height: 100%;
}

/* Responsive Styles */
@media (max-width: 767px) {
  #adm-sidebar {
    left: -100%;
    width: 100%;
  }

  #adm-sidebar.adm-mobile-open {
    left: 0;
  }

  .adm-main-wrapper {
    margin-left: 0;
  }

  .adm-main-wrapper.adm-sidebar-collapsed {
    margin-left: 0;
  }

  .adm-sidebar-close {
    display: block;
  }

  .adm-page-title {
    font-size: var(--adm-font-size-lg);
  }

  .adm-user-menu {
    margin-left: 0;
  }

  .adm-dropdown-toggle {
    padding: var(--adm-spacing-xs) var(--adm-spacing-sm);
  }

  .adm-user-name {
    display: none;
  }
}

/* Tablet Responsive Styles */
@media (min-width: 768px) and (max-width: 991px) {
  #adm-sidebar {
    width: var(--adm-sidebar-collapsed-width);
  }

  .adm-main-wrapper {
    margin-left: var(--adm-sidebar-collapsed-width);
  }

  .adm-nav-label,
  .adm-company-name,
  .adm-sidebar-footer {
    display: none;
  }

  .adm-nav-link {
    justify-content: center;
  }

  .adm-nav-link i {
    margin-right: 0;
  }
}

/* Accessibility */
.adm-btn:focus,
.adm-nav-link:focus,
.adm-dropdown-toggle:focus,
.adm-dropdown-item:focus {
  outline: 2px solid var(--adm-primary-color);
  outline-offset: 2px;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.adm-content-area {
  animation: fadeIn 0.3s ease-in-out;
}

/* Custom Scrollbar */
.adm-dashboard ::-webkit-scrollbar {
  width: 8px;
}

.adm-dashboard ::-webkit-scrollbar-track {
  background: var(--adm-bg-secondary);
}

.adm-dashboard ::-webkit-scrollbar-thumb {
  background: var(--adm-primary-color);
  border-radius: 4px;
}

.adm-dashboard ::-webkit-scrollbar-thumb:hover {
  background: var(--adm-primary-color-dark);
}

/* Utility Classes */
.adm-text-center {
  text-align: center;
}

.adm-mt-lg {
  margin-top: var(--adm-spacing-lg);
}

.adm-mb-md {
  margin-bottom: var(--adm-spacing-md);
}

/* Remove list item dots */
#adm-sidebar .adm-nav {
  list-style-type: none;
  padding-left: 0;
}

/* Print Styles */
@media print {
  #adm-sidebar,
  .adm-top-bar {
    display: none;
  }

  .adm-main-wrapper {
    margin-left: 0;
  }

  .adm-content-area {
    box-shadow: none;
  }
}
</style>