<template>
  <footer class="page-footer" :class="{ 'dark-theme': isDarkTheme }">
    <div class="footer-content container" v-if="footerStore.footerData">
      <div class="footer-section about">
        <h3>{{ footerStore.footerData.ngo_name }}</h3>
        <p>{{ footerStore.footerData.about }}</p>
      </div>
      <div class="footer-section links">
        <h3>Quick Links</h3>
        <ul>
          <li v-for="link in footerStore.footerData.quickLinks" :key="link.url">
            <a :href="link.url">{{ link.text }}</a>
          </li>
        </ul>
      </div>
      <div class="footer-section contact">
        <h3>Contact Us</h3>
        <p><i class="bi bi-geo-alt"></i> {{ footerStore.footerData.address }}</p>
        <p><i class="bi bi-envelope"></i> {{ footerStore.footerData.email }}</p>
        <p><i class="bi bi-phone"></i> {{ footerStore.footerData.phone }}</p>
      </div>
      <div class="footer-section newsletter">
        <h3>Stay Updated</h3>
        <p>Subscribe to our newsletter for updates on our projects and impact.</p>
        <form @submit.prevent="subscribeNewsletter">
          <input type="email" v-model="email" placeholder="Enter your email" required>
          <button type="submit">Subscribe</button>
        </form>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <p>&copy; {{ new Date().getFullYear() }} {{ footerStore.footerData?.ngo_name }}. All rights reserved.</p>
        <div class="social-icons">
          <a v-for="social in footerStore.footerData?.socialMedia" :key="social.platform" :href="social.url" :aria-label="social.platform">
            <i :class="'bi bi-' + social.platform.toLowerCase()"></i>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useFooterStore } from '@/stores/footerStore';

export default {
  name: 'PageFooter',
  props: {
    isDarkTheme: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const footerStore = useFooterStore();
    const email = ref('');

    onMounted(() => {
      footerStore.fetchFooterData();
    });

    const subscribeNewsletter = () => {
      // Implement newsletter subscription logic here
      console.log('Subscribed with email:', email.value);
      // Reset email input after submission
      email.value = '';
    };

    return {
      footerStore,
      email,
      subscribeNewsletter
    };
  }
}
</script>

<style scoped>
.page-footer {
  background-color: var(--light-bg-secondary);
  color: var(--light-text-primary);
  padding-top: 3rem;
}

.dark-theme.page-footer {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}

.footer-section {
  flex: 1;
  min-width: 200px;
}

.footer-section h3 {
  color: var(--light-accent-color);
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.dark-theme .footer-section h3 {
  color: var(--dark-accent-color);
}

.footer-section p {
  margin-bottom: 0.5rem;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
}

.footer-section a {
  color: var(--light-text-primary);
  text-decoration: none;
  transition: color 0.3s ease;
}

.dark-theme .footer-section a {
  color: var(--dark-text-primary);
}

.footer-section a:hover {
  color: var(--light-accent-color);
}

.dark-theme .footer-section a:hover {
  color: var(--dark-accent-color);
}

.newsletter form {
  display: flex;
  margin-top: 1rem;
}

.newsletter input {
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid var(--light-border-color);
  border-radius: 4px 0 0 4px;
}

.dark-theme .newsletter input {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-primary);
  border-color: var(--dark-border-color);
}

.newsletter button {
  padding: 0.5rem 1rem;
  background-color: var(--light-accent-color);
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dark-theme .newsletter button {
  background-color: var(--dark-accent-color);
}

.newsletter button:hover {
  background-color: var(--light-hover-color);
}

.dark-theme .newsletter button:hover {
  background-color: var(--dark-hover-color);
}

.footer-bottom {
  margin-top: 2rem;
  padding: 1rem 0;
  border-top: 1px solid var(--light-border-color);
}

.dark-theme .footer-bottom {
  border-color: var(--dark-border-color);
}

.footer-bottom .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-icons a {
  color: var(--light-text-primary);
  margin-left: 1rem;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.dark-theme .social-icons a {
  color: var(--dark-text-primary);
}

.social-icons a:hover {
  color: var(--light-accent-color);
}

.dark-theme .social-icons a:hover {
  color: var(--dark-accent-color);
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-section {
    margin-bottom: 2rem;
    text-align: center;
  }

  .footer-bottom .container {
    flex-direction: column;
    text-align: center;
  }

  .social-icons {
    margin-top: 1rem;
  }

  .social-icons a {
    margin: 0 0.5rem;
  }

  .newsletter form {
    flex-direction: column;
    align-items: center;
  }

  .newsletter input {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 4px;
  }

  .newsletter button {
    width: 100%;
    border-radius: 4px;
  }
}
</style>