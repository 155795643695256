<template>
  <div class="modal-overlay" @click="$emit('close')">
    <div class="modal-content" :class="{ 'dark-theme': isDarkTheme }" @click.stop>
      <button class="close-button" @click="$emit('close')">&times;</button>
      <div v-if="isLoading" class="preloader">
        <div class="spinner"></div>
        <p>Loading donation options...</p>
      </div>
      <div v-else>
        <h2>Support Our Cause</h2>
        <div class="modal-columns">
          <div class="left-column">
            <h3>Donate Items</h3>
            <p>We accept the following items:</p>
            <ul>
              <li v-for="(item, index) in donationStore.acceptedItems" :key="index">{{ item }}</li>
            </ul>
            <p>You can send items to:</p>
            <div class="address">
              <p>{{ donationStore.ngoAddress }}</p>
              <button @click="copyAddress" class="copy-button">Copy Address</button>
            </div>
          </div>
          <div class="right-column">
            <h3>Monetary Donations</h3>
            <p>Your financial support helps us make a difference. Choose your preferred payment method:</p>
            <div class="payment-buttons">
              <a v-for="method in donationStore.paymentMethods" 
                 :key="method.id" 
                 :href="method.link" 
                 class="payment-button"
                 :class="getPaymentMethodClass(method.name)">
                <i :class="getPaymentMethodIcon(method.name)"></i>
                Donate with {{ method.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useDonationStore } from '@/stores/donationSettings';

export default {
  name: 'DonationModal',
  props: {
    isDarkTheme: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const donationStore = useDonationStore();
    const isLoading = ref(true);

    onMounted(async () => {
      try {
        await donationStore.fetchSettings();
      } catch (error) {
        console.error('Failed to fetch donation settings:', error);
      } finally {
        isLoading.value = false;
      }
    });

    const copyAddress = () => {
      navigator.clipboard.writeText(donationStore.ngoAddress)
        .then(() => alert('Address copied to clipboard!'))
        .catch(err => console.error('Failed to copy address: ', err));
    };

    const getPaymentMethodClass = (methodName) => {
      const name = methodName.toLowerCase().replace(/\s+/g, '-');
      const knownMethods = ['paypal', 'venmo', 'apple-pay', 'credit-card', 'google-pay', 'stripe', 'square', 'cash-app', 'zelle'];
      return knownMethods.includes(name) ? name : 'unknown-method';
    };

    const getPaymentMethodIcon = (methodName) => {
      const name = methodName.toLowerCase();
      const iconMap = {
        'paypal': 'bi-paypal',
        'venmo': 'bi-phone',
        'apple pay': 'bi-apple',
        'credit card': 'bi-credit-card',
        'google pay': 'bi-google',
        'stripe': 'bi-stripe',
        'square': 'bi-square',
        'cash app': 'bi-cash',
        'zelle': 'bi-bank'
      };
      return iconMap[name] || 'bi-credit-card-2-front';
    };

    return {
      donationStore,
      isLoading,
      copyAddress,
      getPaymentMethodClass,
      getPaymentMethodIcon
    };
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modal-content {
  background-color: var(--light-bg-primary);
  color: var(--light-text-primary);
  padding: 2rem;
  border-radius: var(--border-radius);
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.modal-content.dark-theme {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-primary);
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--light-text-primary);
}

.dark-theme .close-button {
  color: var(--dark-text-primary);
}

h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.modal-columns {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.left-column, .right-column {
  flex: 1;
  min-width: 250px;
}

ul {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.address {
  background-color: var(--light-bg-secondary);
  padding: 1rem;
  border-radius: var(--border-radius);
  margin-top: 1rem;
}

.dark-theme .address {
  background-color: var(--dark-bg-secondary);
}

.copy-button {
  background-color: var(--light-accent-color);
  color: var(--light-bg-primary);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  margin-top: 0.5rem;
}

.dark-theme .copy-button {
  background-color: var(--dark-accent-color);
  color: var(--dark-bg-primary);
}

.payment-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.payment-button {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: var(--border-radius);
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  transition: opacity 0.3s ease;
  color: white;
}

.payment-button:hover {
  opacity: 0.8;
}

.payment-button i {
  margin-right: 0.5rem;
}

/* Default styles for payment buttons */
.payment-button {
  background-color: var(--light-accent-color);
}

.dark-theme .payment-button {
  background-color: var(--dark-accent-color);
}

/* Existing styles for payment methods */
.paypal { background-color: #0070ba; }
.venmo { background-color: #3d95ce; }
.apple-pay { background-color: black; }
.credit-card { background-color: #6772e5; }

/* Additional payment method styles */
.google-pay { background-color: #5f6368; }
.stripe { background-color: #6772e5; }
.square { background-color: #006aff; }
.cash-app { background-color: #00c244; }
.zelle { background-color: #6d1ed4; }

/* Style for unknown payment methods */
.unknown-method { 
  background-color: #888888; 
}

/* Preloader styles */
.preloader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--light-accent-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.dark-theme .spinner {
  border-left-color: var(--dark-accent-color);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .modal-content {
    padding: 1.5rem;
  }

  .modal-columns {
    flex-direction: column;
  }
}
</style>