// store/projects.js
import { defineStore } from 'pinia';
import api from '@/axios/api.js';

export const useProjectStore = defineStore('projects', {
  state: () => ({
    projects: []
  }),
  getters: {
    getProjectById: (state) => (id) => state.projects.find(project => project.id === id)
  },
  actions: {
    async fetchProjects() {
      try {
        const response = await api.get('/projects');
        this.projects = response.data;
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    },
    async addProject(project) {
      try {
        const response = await api.post('/projects', project);
        this.projects.push(response.data);
      } catch (error) {
        console.error('Error adding project:', error);
      }
    },
    async updateProject(project) {
      try {
        const response = await api.put(`/projects/${project.id}`, project);
        const index = this.projects.findIndex(p => p.id === project.id);
        if (index !== -1) {
          this.projects[index] = response.data;
        }
      } catch (error) {
        console.error('Error updating project:', error);
      }
    },
    async deleteProject(projectId) {
      try {
        await api.delete(`/projects/${projectId}`);
        this.projects = this.projects.filter(p => p.id !== projectId);
      } catch (error) {
        console.error('Error deleting project:', error);
      }
    }
  }
});