<template>
    <transition name="modal">
      <div v-if="show" class="modal-overlay" @click.self="$emit('close')">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <h3>Default Header</h3>
            </slot>
            <button class="modal-close" @click="$emit('close')">&times;</button>
          </div>
  
          <div class="modal-body">
            <slot name="body">
              Default body
            </slot>
          </div>
  
          <div class="modal-footer">
            <slot name="footer">
              <button class="modal-default-button" @click="$emit('close')">Close</button>
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    name: 'donerModal',
    props: {
      show: {
        type: Boolean,
        default: false
      }
    },
    emits: ['close']
  }
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-container {
    width: 90%;
    max-width: 500px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
  }
  
  .modal-header {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
  }
  
  .modal-close {
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .modal-body {
    padding: 20px;
    max-height: 60vh;
    overflow-y: auto;
  }
  
  .modal-footer {
    padding: 15px;
    border-top: 1px solid #eeeeee;
    text-align: right;
  }
  
  .modal-default-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-enter-active, .modal-leave-active {
    transition: opacity .3s;
  }
  
  .modal-enter-from, .modal-leave-to {
    opacity: 0;
  }
  </style>