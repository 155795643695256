<template>
  <div class="modal-overlay" @click.self="closeModal">
    <div class="modal-content donation-system" :class="{ 'dark-theme': isDarkTheme }">
      <button @click="closeModal" class="close-button" aria-label="Close donation modal">
        &times;
      </button>
      <div class="modal-scrollable-content">
        <h2>Support Our Cause</h2>
        <!-- <p v-if="projectId" class="project-id">Donating to Project ID: {{ projectId }}</p> -->
        <div class="amount-options">
          <button 
            v-for="amount in predefinedAmounts" 
            :key="amount" 
            @click="selectAmount(amount)"
            :class="{ 'selected': selectedAmount === amount }"
          >
            ${{ amount }}
          </button>
          <div class="custom-amount">
            <input 
              type="number" 
              v-model="customAmount" 
              placeholder="Custom amount"
              @input="selectCustomAmount"
            >
          </div>
        </div>
        <div class="donation-frequency">
          <label>
            <input type="radio" v-model="frequency" value="one-time">
            <span>One-time</span>
          </label>
          <label>
            <input type="radio" v-model="frequency" value="monthly">
            <span>Monthly</span>
          </label>
        </div>
        <div class="donor-info">
          <input v-model="email" @blur="checkExistingDonor" placeholder="Email" type="email">
          <input v-model="name" placeholder="Full Name" :disabled="isReturningDonor">
          <p v-if="isReturningDonor" class="returning-donor-message">Welcome back, {{ name }}!</p>
        </div>
        <div v-if="error" class="error-message">{{ error }}</div>
        <div v-if="donationComplete" class="thank-you-message">
          Thank you for your {{ frequency }} donation of ${{ finalAmount }}!
        </div>
      </div>
      <div class="modal-footer">
        <button @click="initializeFlutterwave" :disabled="!canDonate" class="flutterwave-button">
          Donate ${{ finalAmount }} 
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch, onUnmounted } from 'vue'
import { useDonationStore } from '@/stores/donationStore'

export default {
  name: 'DonationSystem',
  props: {
    projectId: {
      type: Number,
      default: null
    },
    isDarkTheme: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const donationStore = useDonationStore()
    const predefinedAmounts = [20, 30, 50, 100]
    const selectedAmount = ref(null)
    const customAmount = ref('')
    const frequency = ref('one-time')
    const name = ref('')
    const email = ref('')
    const donationComplete = ref(false)
    const error = ref(null)
    const isReturningDonor = ref(false)

    const finalAmount = computed(() => {
      return selectedAmount.value || (customAmount.value ? parseFloat(customAmount.value) : 0)
    })

    const canDonate = computed(() => {
      return finalAmount.value > 0 && name.value && email.value
    })

    const selectAmount = (amount) => {
      selectedAmount.value = amount
      customAmount.value = ''
    }

    const selectCustomAmount = () => {
      selectedAmount.value = null
    }

    const closeModal = () => {
      emit('close')
    }

    const loadFlutterwaveScript = () => {
      return new Promise((resolve, reject) => {
        if (window.FlutterwaveCheckout) {
          resolve()
          return
        }
        const script = document.createElement('script')
        script.src = "https://checkout.flutterwave.com/v3.js"
        script.async = true
        script.onload = () => resolve()
        script.onerror = () => reject(new Error('Failed to load Flutterwave SDK'))
        document.body.appendChild(script)
      })
    }

    const checkExistingDonor = async () => {
      if (email.value) {
        try {
          const donor = await donationStore.checkExistingDonor(email.value)
          if (donor) {
            name.value = donor.name
            isReturningDonor.value = true
          } else {
            isReturningDonor.value = false
          }
        } catch (error) {
          console.error('Error checking existing donor:', error)
        }
      }
    }

    const initializeFlutterwave = async () => {
      error.value = null
      try {
        await loadFlutterwaveScript()

        const donationData = {
          amount: finalAmount.value,
          frequency: frequency.value,
          name: name.value,
          email: email.value,
          projectId: props.projectId,
          isReturningDonor: isReturningDonor.value
        }

        window.FlutterwaveCheckout({
          public_key: "FLWPUBK_TEST-de4ea0b38d29957190e589343d727ca3-X",
          tx_ref: Date.now().toString(),
          amount: donationData.amount,
          currency: "USD",
          payment_options: "card,mobilemoney,ussd",
          customer: {
            email: donationData.email,
            name: donationData.name,
          },
          customizations: {
            title: "Donation",
            description: `Donation for Project ${donationData.projectId || 'General'}`,
            logo: "https://your-logo-url.com/logo.png",
          },
          callback: async function(response) {
            if (response.status === "successful") {
              try {
                const verificationResponse = await donationStore.verifyPayment({
                  transaction_id: response.transaction_id,
                  ...donationData
                })
                if (verificationResponse.success) {
                  donationComplete.value = true
                  setTimeout(() => {
                    closeModal()
                    window.location.href = '/thank-you'
                  }, 3000)
                } else {
                  error.value = 'Payment verification failed. Please contact support.'
                }
              } catch (verifyError) {
                error.value = donationStore.error || 'An error occurred during payment verification.'
                console.error('Verification error:', verifyError)
              }
            } else {
              error.value = 'Payment was not successful. Please try again.'
            }
          },
          onclose: function() {
            // Handle modal close
          },
        })
      } catch (error) {
        console.error('Error initializing Flutterwave:', error)
        error.value = 'There was an error initializing the payment. Please try again.'
      }
    }

    onMounted(() => {
      document.body.style.overflow = 'hidden'
    })

    onUnmounted(() => {
      document.body.style.overflow = 'auto'
    })

    watch(() => props.projectId, () => {
      selectedAmount.value = null
      customAmount.value = ''
      frequency.value = 'one-time'
      name.value = ''
      email.value = ''
      donationComplete.value = false
      error.value = null
    })

    return {
      predefinedAmounts,
      selectedAmount,
      customAmount,
      frequency,
      name,
      email,
      finalAmount,
      canDonate,
      donationComplete,
      error,
      selectAmount,
      selectCustomAmount,
      initializeFlutterwave,
      closeModal,
      isReturningDonor,
      checkExistingDonor
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;  /* Ensure it's above the bottom navigation */
  padding: 20px;
  box-sizing: border-box;
}

.modal-content {
  background-color: var(--light-bg-primary);
  color: var(--light-text-primary);
  border-radius: 12px 12px 0 0;  /* Rounded corners only at the top */
  width: 100%;
  max-width: 450px;
  height: 90vh;  /* Fixed height */
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-scrollable-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 2rem;
  padding-bottom: 80px;  /* Add extra padding at the bottom */
}

.modal-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: var(--light-bg-secondary);
  border-top: 1px solid var(--light-border-color);
}


.dark-theme {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-primary);
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
}



.dark-theme .modal-footer {
  background-color: var(--dark-bg-secondary);
  border-top-color: var(--dark-border-color);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--light-text-primary);
  padding: 5px 10px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  z-index: 1;
}

.dark-theme .close-button {
  color: var(--dark-text-primary);
}

.close-button:hover,
.close-button:focus {
  background-color: rgba(0, 0, 0, 0.1);
}

.dark-theme .close-button:hover,
.dark-theme .close-button:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--light-accent-color);
}

.dark-theme h2 {
  color: var(--dark-accent-color);
}

.project-id {
  text-align: center;
  margin-bottom: 1rem;
  font-style: italic;
  font-size: 0.9rem;
}

.amount-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.amount-options button {
  padding: 0.6rem;
  border: 2px solid var(--light-border-color);
  background-color: var(--light-bg-secondary);
  color: var(--light-text-primary);
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dark-theme .amount-options button {
  border-color: var(--dark-border-color);
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
}

.amount-options button:hover {
  background-color: var(--light-hover-color);
}

.dark-theme .amount-options button:hover {
  background-color: var(--dark-hover-color);
}

.amount-options button.selected {
  background-color: var(--light-accent-color);
  color: white;
  border-color: var(--light-accent-color);
}

.dark-theme .amount-options button.selected {
  background-color: var(--dark-accent-color);
  border-color: var(--dark-accent-color);
}

.custom-amount {
  grid-column: span 2;
}

.custom-amount input,
.donor-info input {
  width: 100%;
  padding: 0.6rem;
  border: 2px solid var(--light-border-color);
  background-color: var(--light-bg-primary);
  color: var(--light-text-primary);
  border-radius: 8px;
  font-size: 0.9rem;
  transition: border-color 0.3s ease;
}

.dark-theme .custom-amount input,
.dark-theme .donor-info input {
  border-color: var(--dark-border-color);
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
}

.custom-amount input:focus,
.donor-info input:focus {
  outline: none;
  border-color: var(--light-accent-color);
}

.dark-theme .custom-amount input:focus,
.dark-theme .donor-info input:focus {
  border-color: var(--dark-accent-color);
}

.donation-frequency {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.donation-frequency label {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
  font-size: 0.9rem;
}
.donor-info {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.returning-donor-message {
  color: var(--light-accent-color);
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.dark-theme .returning-donor-message {
  color: var(--dark-accent-color);
}

.flutterwave-button {
  width: 100%;
  padding: 0.8rem;
  background-color: var(--light-accent-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dark-theme .flutterwave-button {
  background-color: var(--dark-accent-color);
}

.flutterwave-button:hover {
  background-color: var(--light-hover-color);
}

.dark-theme .flutterwave-button:hover {
  background-color: var(--dark-hover-color);
}

.flutterwave-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.thank-you-message {
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
  color: #3c763d;
  padding: 0.8rem;
  border-radius: 8px;
  margin-top: 1rem;
  text-align: center;
  font-size: 0.9rem;
}

.dark-theme .thank-you-message {
  background-color: #1e3a1e;
  border-color: #2c5a2c;
  color: #a3d7a3;
}

.error-message {
  color: #a94442;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  padding: 0.8rem;
  border-radius: 8px;
  margin-top: 1rem;
  text-align: center;
  font-size: 0.9rem;
}

.dark-theme .error-message {
  color: #ff6b6b;
  background-color: #4a1e1e;
  border-color: #5a2c2c;
}

@media (max-width: 480px) {
  .modal-content {
    height: calc(100vh - 70px);  /* Adjust for bottom navigation */
    border-radius: 12px 12px 0 0;  /* Rounded corners only at the top */
    margin-bottom: 0;  /* Align to bottom of screen */
  }

  .modal-scrollable-content {
    padding: 1.5rem;
    padding-bottom: 5rem;  /* Increased padding at the bottom */
  }

  .modal-footer {
    padding: 1rem;
  }
  .close-button {
    top: 5px;
    right: 5px;
    font-size: 1.8rem;
    padding: 8px 12px;
  }

  h2 {
    font-size: 1.5rem;
  }

  .amount-options {
    grid-template-columns: 1fr;
  }

  .custom-amount {
    grid-column: span 1;
  }

  .donation-frequency {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
}

@media (max-height: 700px) {
  .modal-content {
    height: calc(100vh - 80px);  /* Adjust for bottom navigation */
  }

  .modal-scrollable-content {
    padding: 1.5rem;
    padding-bottom: 1rem;
  }

  .modal-footer {
    padding: 1rem 1.5rem;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.8rem;
  }

  .amount-options,
  .donation-frequency,
  .donor-info {
    margin-bottom: 0.8rem;
  }

  .amount-options button,
  .custom-amount input,
  .donor-info input,
  .flutterwave-button {
    padding: 0.5rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .modal-content,
  .close-button,
  .amount-options button,
  .custom-amount input,
  .donor-info input,
  .flutterwave-button {
    transition: none;
  }
}

/* Accessibility improvements */
.flutterwave-button:focus,
.amount-options button:focus,
.custom-amount input:focus,
.donor-info input:focus,
.close-button:focus {
  outline: 2px solid var(--light-accent-color);
  outline-offset: 2px;
}

.dark-theme .flutterwave-button:focus,
.dark-theme .amount-options button:focus,
.dark-theme .custom-amount input:focus,
.dark-theme .donor-info input:focus,
.dark-theme .close-button:focus {
  outline-color: var(--dark-accent-color);
}

/* Improve visibility of radio buttons */
.donation-frequency input[type="radio"] {
  margin: 0;
  width: 1em;
  height: 1em;
}

/* Add a subtle animation for the thank you and error messages */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.thank-you-message,
.error-message {
  animation: fadeIn 0.3s ease-out;
}
</style>