import { defineStore } from 'pinia';
import api from '@/axios/api.js';

export const useFooterStore = defineStore('footer', {
  state: () => ({
    footerData: null,
    loading: false,
    error: null
  }),
  actions: {
    async fetchFooterData() {
      this.loading = true;
      this.error = null;
      try {
        const response = await api.get('/footer');
        this.footerData = response.data;
        if (!this.footerData) {
          this.error = 'No footer data found';
        }
      } catch (error) {
        console.error('Error fetching footer data:', error);
        this.error = 'Failed to fetch footer data: ' + (error.response?.data?.message || error.message);
      } finally {
        this.loading = false;
      }
    },
    async updateFooterData(updatedData) {
      this.loading = true;
      this.error = null;
      try {
        const response = await api.put('/footer', updatedData);
        this.footerData = response.data;
      } catch (error) {
        console.error('Error updating footer data:', error);
        this.error = 'Failed to update footer data: ' + (error.response?.data?.message || error.message);
      } finally {
        this.loading = false;
      }
    },
    async createFooterData(newData) {
      this.loading = true;
      this.error = null;
      try {
        const response = await api.post('/footer', newData);
        this.footerData = response.data;
      } catch (error) {
        console.error('Error creating footer data:', error);
        this.error = 'Failed to create footer data: ' + (error.response?.data?.message || error.message);
      } finally {
        this.loading = false;
      }
    }
  }
});