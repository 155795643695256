<template>
    <div class="adm-image-management">
      <h2 class="adm-section-title">Image Management</h2>
  
      <!-- Image Upload Section -->
      <div class="adm-image-upload">
        <h3 class="adm-subsection-title">Upload New Images</h3>
        <FilePond
          ref="pond"
          :server="serverOptions"
          :allow-multiple="true"
          :accepted-file-types="['image/*']"
          :label-idle="'Drag & Drop your images or <span class=\'filepond--label-action\'>Browse</span>'"
          @processfile="handleProcessedFile"
          class="adm-filepond"
        />
      </div>
  
      <!-- Bulk Actions -->
      <div class="adm-bulk-actions" v-if="selectedImages.length > 0">
        <button @click="bulkDelete" class="adm-btn adm-btn-danger">
          <i class="bi bi-trash"></i> Delete Selected ({{ selectedImages.length }})
        </button>
      </div>
  
      <!-- Image List Section -->
      <div class="adm-image-list" v-if="imageStore.images.length">
        <h3 class="adm-subsection-title">All Images</h3>
        <div class="adm-image-grid">
            <div v-for="image in paginatedImages" :key="`${image.source}-${image.id}`" class="adm-image-item">
              <div class="adm-image-checkbox">
                <input 
                  type="checkbox" 
                  :id="`image-${image.id}`" 
                  :value="image" 
                  v-model="selectedImages"
                  class="adm-checkbox-input"
                >
                <label :for="`image-${image.id}`" class="adm-checkbox-label"></label>
              </div>
            <img :src="image.image_url" :alt="image.alt_text" class="adm-image-preview">
            <div class="adm-image-info">
              <p><strong>Source:</strong> <span class="badge">{{ image.source }}</span></p>
              <p><strong>Alt Text:</strong> {{ image.alt_text || 'None' }}</p>
            </div>
            <div class="adm-image-actions">
              <button @click="editImage(image)" class="adm-btn adm-btn-secondary adm-btn-sm">
                <i class="bi bi-pencil"></i> Edit
              </button>
              <button @click="deleteImage(image)" class="adm-btn adm-btn-danger adm-btn-sm">
                <i class="bi bi-trash"></i> Delete
              </button>
              <button @click="copyImageUrl(image)" class="adm-btn adm-btn-primary adm-btn-sm">
                <i class="bi bi-clipboard"></i> Copy URL
              </button>
            </div>
          </div>
        </div>
      </div>
      <p v-else class="adm-no-data">No images found.</p>
  
      <!-- Pagination -->
      <div class="adm-pagination">
        <button @click="prevPage" :disabled="currentPage === 1" class="adm-btn adm-btn-secondary">
          <i class="bi bi-chevron-left"></i> Previous
        </button>
        <span class="adm-page-info">Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="adm-btn adm-btn-secondary">
          Next <i class="bi bi-chevron-right"></i>
        </button>
      </div>
  
      <!-- Image Edit Modal -->
      <div v-if="editingImage" class="adm-modal">
        <div class="adm-modal-content">
          <span class="adm-modal-close" @click="closeEditModal">&times;</span>
          <h3 class="adm-modal-title">Edit Image Alt Text</h3>
          <input v-model="editingImage.alt_text" type="text" class="adm-form-input">
          <button @click="saveImageEdit" class="adm-btn adm-btn-primary">
            <i class="bi bi-save"></i> Save
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, computed, onMounted } from 'vue';
  import { useImageStore } from '@/stores/imageStore';
  import vueFilePond from "vue-filepond";
  import FilePondPluginImagePreview from "filepond-plugin-image-preview";
  import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
  import "filepond/dist/filepond.min.css";
  import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
  
  const FilePond = vueFilePond(FilePondPluginImagePreview, FilePondPluginFileValidateType);
  
  export default {
    name: 'AdminImageManagement',
    components: {
      FilePond
    },
    setup() {
      const imageStore = useImageStore();
      const editingImage = ref(null);
      const selectedImages = ref([]);
      const currentPage = ref(1);
      const itemsPerPage = 20;
  
      const serverOptions = {
        process: (fieldName, file, metadata, load, error,) => {
          imageStore.uploadImage(file)
            .then(() => load())
            .catch(() => error('Upload failed'));
        }
      };
  
      const paginatedImages = computed(() => {
        const start = (currentPage.value - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        return imageStore.images.slice(start, end);
      });
  
      const totalPages = computed(() => Math.ceil(imageStore.images.length / itemsPerPage));
  
      onMounted(() => {
        imageStore.fetchImages();
      });
  
      const handleProcessedFile = (error, file) => {
        if (error) {
          console.error('File upload error:', error);
        } else {
          console.log('File uploaded successfully:', file);
        }
      };
  
      const editImage = (image) => {
        editingImage.value = { ...image };
      };
  
      const closeEditModal = () => {
        editingImage.value = null;
      };
  
      const saveImageEdit = async () => {
        if (editingImage.value) {
          await imageStore.updateImage(editingImage.value);
          closeEditModal();
        }
      };
  
      const deleteImage = async (image) => {
        if (confirm('Are you sure you want to delete this image?')) {
          await imageStore.deleteImage(image);
        }
      };
  
      const bulkDelete = async () => {
        if (confirm(`Are you sure you want to delete ${selectedImages.value.length} images?`)) {
          await imageStore.bulkDeleteImages(selectedImages.value);
          selectedImages.value = [];
        }
      };
  
      const copyImageUrl = (image) => {
        navigator.clipboard.writeText(image.image_url)
          .then(() => alert('Image URL copied to clipboard!'))
          .catch(err => console.error('Failed to copy URL: ', err));
      };
  
      const prevPage = () => {
        if (currentPage.value > 1) currentPage.value--;
      };
  
      const nextPage = () => {
        if (currentPage.value < totalPages.value) currentPage.value++;
      };
  
      return {
        imageStore,
        editingImage,
        selectedImages,
        currentPage,
        totalPages,
        paginatedImages,
        serverOptions,
        handleProcessedFile,
        editImage,
        closeEditModal,
        saveImageEdit,
        deleteImage,
        bulkDelete,
        copyImageUrl,
        prevPage,
        nextPage
      };
    }
  };
  </script>

<style scoped>
.adm-image-management {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.adm-section-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--adm-primary-color);
}

.adm-subsection-title {
  font-size: 20px;
  margin-bottom: 15px;
  color: var(--adm-secondary-color);
}

.adm-image-upload {
  margin-bottom: 30px;
}

.adm-filepond {
  margin-top: 15px;
}

.adm-bulk-actions {
  margin-bottom: 20px;
}

.adm-image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.adm-image-item {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .adm-image-checkbox {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
  }
  
  .adm-checkbox-input {
    display: none;
  }
  
  .adm-checkbox-label {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: white;
    border: 2px solid var(--adm-secondary-color);
    border-radius: 4px;
    cursor: pointer;
  }
  
  .adm-checkbox-input:checked + .adm-checkbox-label {
    background-color: var(--adm-secondary-color);
    position: relative;
  }
  
  .adm-checkbox-input:checked + .adm-checkbox-label::after {
    content: '\2714';
    font-size: 14px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .adm-bulk-actions {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }

.adm-image-preview {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.adm-image-info {
  padding: 10px;
  font-size: 14px;
}

.adm-image-actions {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: #f8f9fa;
}

.adm-no-data {
  text-align: center;
  color: #6c757d;
  margin-top: 20px;
}

.adm-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.adm-page-info {
  margin: 0 15px;
}

.adm-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.adm-modal-content {
  background-color: var(--adm-secondary-color);
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
}

.adm-modal-close {
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.adm-modal-title {
  margin-bottom: 15px;
}

.adm-form-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.adm-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.adm-btn i {
  margin-right: 5px;
}

.adm-btn-primary {
  color: white;
}

.adm-btn-secondary {
  background-color: var(--adm-secondary-color);
  color: white;
}

.adm-btn-danger {
  background-color: #dc3545;
  color: white;
}

.adm-btn-sm {
  padding: 4px 8px;
  font-size: 12px;
}

@media (max-width: 768px) {
  .adm-image-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .adm-image-actions {
    flex-direction: column;
  }

  .adm-image-actions .adm-btn {
    width: 100%;
    margin-bottom: 5px;
  }

  .adm-pagination {
    flex-direction: column;
  }

  .adm-pagination .adm-btn {
    width: 100%;
    margin-bottom: 10px;
  }

  .adm-page-info {
    margin: 10px 0;
  }
}
</style>