import { defineStore } from 'pinia';
import api from '@/axios/api.js';

export const useAboutStore = defineStore('about', {
  state: () => ({
    title: '',
    short_about: '',
    mission: '',
    vision: '',
    goals: [],
    impacts: [],
    images: [],
    newImages: [],
    loading: false,
    error: null
  }),
  actions: {
    async fetchAboutSection() {
      this.loading = true;
      try {
        const response = await api.get('/about');
        const data = response.data;
        this.$patch(data);
        this.error = null;
      } catch (error) {
        this.error = 'Failed to fetch about section data';
        console.error('Error fetching about section:', error);
      } finally {
        this.loading = false;
      }
    },
    async updateAboutSection(aboutData) {
      this.loading = true;
      try {
        const dataToSend = {
          ...aboutData,
          newImages: this.newImages
        };

        await api.put('/about', dataToSend);
        
        this.$patch({
          ...aboutData,
          images: [...this.images, ...this.newImages]
        });
        
        this.newImages = [];
        this.error = null;
      } catch (error) {
        this.error = 'Failed to update about section data';
        console.error('Error updating about section:', error);
      } finally {
        this.loading = false;
      }
    },
    async uploadImage(file) {
      const formData = new FormData();
      formData.append('image', file);
      
      try {
        const response = await api.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.newImages.push({
          image_url: response.data.fileName,
          alt_text: file.name
        });
        return response.data.url;
      } catch (error) {
        console.error('Error uploading image:', error);
        throw error;
      }
    },
    async removeImage(imageUrl) {
      try {
        await api.delete('/remove-image', { data: { url: imageUrl } });
        this.images = this.images.filter(img => img.image_url !== imageUrl);
        this.newImages = this.newImages.filter(img => img.image_url !== imageUrl);
      } catch (error) {
        console.error('Error removing image:', error);
        throw error;
      }
    }
  }
});