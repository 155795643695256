import { defineStore } from 'pinia'
import api from '@/axios/api.js';

export const useDonationStore = defineStore('donation', {
  state: () => ({
    acceptedItems: [],
    ngoAddress: '',
    paymentMethods: []
  }),
  actions: {
    async fetchSettings() {
      try {
        const response = await api.get('/donation-settings')
        this.acceptedItems = response.data.accepted_items || []
        this.ngoAddress = response.data.ngo_address || ''
        this.paymentMethods = response.data.payment_methods || []
      } catch (error) {
        console.error('Error fetching donation settings:', error)
        throw error
      }
    },
    async addAcceptedItem(item) {
      try {
        await api.post('/donation-settings/accepted-items', { item })
        this.acceptedItems.push(item)
      } catch (error) {
        console.error('Error adding accepted item:', error)
        throw error
      }
    },
    async removeAcceptedItem(item) {
      try {
        await api.delete(`/donation-settings/accepted-items/${encodeURIComponent(item)}`);
        this.acceptedItems = this.acceptedItems.filter(i => i !== item);
      } catch (error) {
        console.error('Error deleting accepted item:', error);
        throw error;
      }
    },
    async updateNGOAddress(address) {
      try {
        await api.put('/donation-settings/ngo-address', { address })
        this.ngoAddress = address
      } catch (error) {
        console.error('Error updating NGO address:', error)
        throw error
      }
    },
    async addPaymentMethod(method) {
      try {
        await api.post('/donation-settings/payment-methods', method)
        this.paymentMethods.push(method)
      } catch (error) {
        console.error('Error adding payment method:', error)
        throw error
      }
    },
    async removePaymentMethod(id) {
      try {
        await api.delete(`/donation-settings/payment-methods/${id}`);
        this.paymentMethods = this.paymentMethods.filter(m => m.id !== id);
      } catch (error) {
        console.error('Error deleting payment method:', error);
        throw error;
      }
    }
  }
})