<template>
  <div class="adm-donor-management">
    <h1 class="adm-section-title">Donor Management</h1>

    <!-- Search and filter options -->
    <div class="adm-filter-container">
      <input v-model="searchQuery" @input="handleSearch" placeholder="Search donors..." class="adm-form-input" />
      <select v-model="sortBy" @change="handleSort" class="adm-form-select">
        <option value="name">Sort by Name</option>
        <option value="email">Sort by Email</option>
        <option value="created_at">Sort by Date Joined</option>
        <option value="total_donated">Sort by Total Donated</option>
      </select>
      <select v-model="sortOrder" @change="handleSort" class="adm-form-select">
        <option value="asc">Ascending</option>
        <option value="desc">Descending</option>
      </select>
    </div>

    <!-- Donor Table -->
    <div class="adm-table-responsive">
      <table class="adm-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Date Joined</th>
            <th>Total Donated</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="donor in donorStore.donors" :key="donor.id">
            <td data-label="Name">{{ donor.name }}</td>
            <td data-label="Email">{{ donor.email }}</td>
            <td data-label="Date Joined">{{ formatDate(donor.created_at) }}</td>
            <td data-label="Total Donated">{{ formatCurrency(donor.total_donated) }}</td>
            <td data-label="Actions">
              <button @click="viewDonorDetails(donor)" class="adm-btn adm-btn-primary adm-btn-sm">View</button>
              <button @click="openEmailModal(donor)" class="adm-btn adm-btn-secondary adm-btn-sm">Email</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination -->
    <div class="adm-pagination">
      <button @click="changePage(-1)" :disabled="donorStore.currentPage === 1" class="adm-btn adm-btn-primary">Previous</button>
      <span class="adm-pagination-info">Page {{ donorStore.currentPage }} of {{ donorStore.totalPages }}</span>
      <button @click="changePage(1)" :disabled="donorStore.currentPage === donorStore.totalPages" class="adm-btn adm-btn-primary">Next</button>
    </div>

    <!-- Bulk Actions -->
    <div class="adm-bulk-actions">
      <button @click="openBulkEmailModal" class="adm-btn adm-btn-secondary">Send Bulk Email</button>
    </div>

    <!-- Donor Details Modal -->
    <Modal :show="showDonorDetailsModal" @close="closeDonorDetailsModal">
      <template #header>
        <h3 class="adm-modal-title">Donor Details: {{ donorStore.selectedDonor?.name }}</h3>
      </template>
      <template #body>
        <div v-if="donorStore.selectedDonor" class="adm-donor-details">
          <p><strong>Email:</strong> {{ donorStore.selectedDonor.email }}</p>
          <p><strong>Date Joined:</strong> {{ formatDate(donorStore.selectedDonor.joined_date) }}</p>
          <p><strong>Total Donated:</strong> {{ formatCurrency(donorStore.totalDonation) }}</p>
          <p><strong>Number of Donations:</strong> {{ donorStore.paymentCount }}</p>
          
          <h4 class="adm-subtitle">Donation History</h4>
          <div class="adm-table-responsive">
            <table class="adm-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Frequency</th>
                  <th>Status</th>
                  <th>Project</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="payment in donorStore.donorPayments" :key="payment.payment_id">
                  <td data-label="Date">{{ formatDate(payment.payment_date) }}</td>
                  <td data-label="Amount">{{ formatCurrency(payment.amount) }}</td>
                  <td data-label="Frequency">{{ payment.frequency }}</td>
                  <td data-label="Status">{{ payment.payment_status }}</td>
                  <td data-label="Project">{{ payment.project_name || 'General Fund' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </Modal>

    <!-- Email Modal -->
    <Modal :show="showEmailModal" @close="closeEmailModal">
      <template #header>
        <h3 class="adm-modal-title">Send Email to {{ selectedDonor.name }}</h3>
      </template>
      <template #body>
        <form @submit.prevent="sendEmail" class="adm-form">
          <div class="adm-form-group">
            <label class="adm-form-label">Subject:</label>
            <input v-model="emailSubject" required class="adm-form-input" />
          </div>
          <div class="adm-form-group">
            <label class="adm-form-label">Message:</label>
            <textarea v-model="emailMessage" required class="adm-form-textarea" rows="5"></textarea>
          </div>
          <button type="submit" class="adm-btn adm-btn-primary">Send Email</button>
        </form>
      </template>
    </Modal>

    <!-- Bulk Email Modal -->
    <Modal :show="showBulkEmailModal" @close="closeBulkEmailModal">
      <template #header>
        <h3 class="adm-modal-title">Send Bulk Email to Donors</h3>
      </template>
      <template #body>
        <form @submit.prevent="sendBulkEmail" class="adm-form">
          <div class="adm-form-group">
            <label class="adm-form-label">Subject:</label>
            <input v-model="bulkEmailSubject" required class="adm-form-input" />
          </div>
          <div class="adm-form-group">
            <label class="adm-form-label">Message:</label>
            <textarea v-model="bulkEmailMessage" required class="adm-form-textarea" rows="5"></textarea>
          </div>
          <div class="adm-form-group">
            <label class="adm-form-label">Filter:</label>
            <select v-model="bulkEmailFilter" class="adm-form-select">
              <option value="all">All Donors</option>
              <option value="recent">Donors from last 30 days</option>
              <option value="top">Top 10% Donors</option>
            </select>
          </div>
          <button type="submit" class="adm-btn adm-btn-primary">Send Bulk Email</button>
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useDonorManagementStore } from '@/stores/donorManagement';
import Modal from '@/studio/donerModal.vue'; 

export default {
  components: {
    Modal
  },
  setup() {
  const donorStore = useDonorManagementStore();
  const showDonorDetailsModal = ref(false);
  const showEmailModal = ref(false);
  const showBulkEmailModal = ref(false);
  const selectedDonor = ref({});
  const emailSubject = ref('');
  const emailMessage = ref('');
  const bulkEmailSubject = ref('');
  const bulkEmailMessage = ref('');
  const bulkEmailFilter = ref('all');

  onMounted(() => {
    donorStore.fetchDonors();
  });

    const handleSearch = () => {
      donorStore.setSearchQuery(donorStore.searchQuery);
    };

    const handleSort = () => {
      donorStore.setSorting(donorStore.sortBy);
    };

    const changePage = (direction) => {
      const newPage = donorStore.currentPage + direction;
      donorStore.setPage(newPage);
    };

   const viewDonorDetails = async (donor) => {
    try {
      showDonorDetailsModal.value = true; // Show modal before fetching to indicate loading
      await donorStore.fetchDonorPayments(donor.id);
    } catch (error) {
      console.error('Error fetching donor details:', error);
      // Error is now handled in the template based on donorStore.error
    }
  };

  const closeDonorDetailsModal = () => {
    showDonorDetailsModal.value = false;
    donorStore.selectedDonor = null;
    donorStore.donorPayments = [];
    donorStore.error = null;
  };
    const openEmailModal = (donor) => {
    selectedDonor.value = donor;
    emailSubject.value = '';
    emailMessage.value = '';
    showEmailModal.value = true;
  };

  const closeEmailModal = () => {
    showEmailModal.value = false;
    selectedDonor.value = {};
    emailSubject.value = '';
    emailMessage.value = '';
  };

  const sendEmail = async () => {
    try {
      const result = await donorStore.sendEmail(
        selectedDonor.value.id,
        emailSubject.value,
        emailMessage.value
      );
      if (result) {
        alert('Email sent successfully');
        closeEmailModal();
      } else {
        alert('Failed to send email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email');
    }
  };


  const openBulkEmailModal = () => {
    bulkEmailSubject.value = '';
    bulkEmailMessage.value = '';
    bulkEmailFilter.value = 'all';
    showBulkEmailModal.value = true;
  };

  const closeBulkEmailModal = () => {
    showBulkEmailModal.value = false;
    bulkEmailSubject.value = '';
    bulkEmailMessage.value = '';
    bulkEmailFilter.value = 'all';
  };

  const sendBulkEmail = async () => {
    try {
      const result = await donorStore.sendBulkEmail(
        bulkEmailSubject.value,
        bulkEmailMessage.value,
        bulkEmailFilter.value
      );
      alert(result.message);
      closeBulkEmailModal();
    } catch (error) {
      console.error('Error sending bulk email:', error);
      alert('Failed to send bulk email');
    }
  };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString();
    };

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
    };

    return {
      donorStore,
    showDonorDetailsModal,
    showEmailModal,
    showBulkEmailModal,
    selectedDonor,
    emailSubject,
    emailMessage,
    bulkEmailSubject,
    bulkEmailMessage,
    bulkEmailFilter,
    handleSearch,
    handleSort,
    changePage,
    viewDonorDetails,
    openEmailModal,
    sendEmail,
    openBulkEmailModal,
    sendBulkEmail,
    formatDate,
    formatCurrency,
    closeEmailModal,
    closeBulkEmailModal,
    closeDonorDetailsModal
    };
  }
};
</script>

<style scoped>
.adm-donor-management {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--adm-spacing-md);
}

.adm-section-title {
  font-size: var(--adm-font-size-xl);
  margin-bottom: var(--adm-spacing-lg);
  color: var(--adm-primary-color);
  font-weight: var(--adm-font-weight-bold);
  text-align: center;
}

.adm-filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--adm-spacing-sm);
  margin-bottom: var(--adm-spacing-md);
}

.adm-form-input,
.adm-form-select {
  flex: 1;
  min-width: 200px;
  padding: var(--adm-spacing-sm);
  border: 1px solid var(--adm-border-color);
  border-radius: var(--adm-border-radius-sm);
  font-size: var(--adm-font-size-base);
  background-color: var(--adm-bg-primary);
  color: var(--adm-text-color);
}

.adm-form-input:focus,
.adm-form-select:focus {
  outline: none;
  border-color: var(--adm-primary-color);
  box-shadow: 0 0 0 2px rgba(var(--adm-primary-color-rgb), 0.2);
}

.adm-table-responsive {
  overflow-x: auto;
  margin-bottom: var(--adm-spacing-md);
}

.adm-table {
  width: 100%;
  border-collapse: collapse;
}

.adm-table th,
.adm-table td {
  padding: var(--adm-spacing-sm);
  border: 1px solid var(--adm-border-color);
  text-align: left;
}

.adm-table th {
  background-color: var(--adm-bg-secondary);
  font-weight: var(--adm-font-weight-bold);
  color: var(--adm-text-color);
}

.adm-table tr:nth-child(even) {
  background-color: var(--adm-bg-secondary);
}

.adm-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--adm-spacing-md);
  flex-wrap: wrap;
  gap: var(--adm-spacing-sm);
}

.adm-pagination-info {
  text-align: center;
  flex: 1;
}

.adm-bulk-actions {
  margin-top: var(--adm-spacing-md);
}

.adm-btn {
  padding: var(--adm-spacing-sm) var(--adm-spacing-md);
  border: none;
  border-radius: var(--adm-border-radius-sm);
  cursor: pointer;
  font-size: var(--adm-font-size-base);
  font-weight: var(--adm-font-weight-medium);
  transition: var(--adm-transition);
}

.adm-btn-primary {
  background-color: var(--adm-primary-color);
  color: var(--adm-bg-primary);
}

.adm-btn-primary:hover,
.adm-btn-primary:focus {
  background-color: var(--adm-primary-color-dark);
}

.adm-btn-secondary {
  background-color: var(--adm-secondary-color);
  color: var(--adm-bg-primary);
}

.adm-btn-secondary:hover,
.adm-btn-secondary:focus {
  background-color: var(--adm-secondary-color-dark);
}

.adm-btn-sm {
  padding: var(--adm-spacing-xs) var(--adm-spacing-sm);
  font-size: var(--adm-font-size-sm);
}

.adm-modal-title {
  font-size: var(--adm-font-size-lg);
  font-weight: var(--adm-font-weight-bold);
  margin-bottom: var(--adm-spacing-md);
  color: var(--adm-primary-color);
}

.adm-donor-details p {
  margin-bottom: var(--adm-spacing-sm);
}

.adm-subtitle {
  font-size: var(--adm-font-size-lg);
  font-weight: var(--adm-font-weight-bold);
  margin: var(--adm-spacing-md) 0;
  color: var(--adm-text-color);
}

.adm-form-group {
  margin-bottom: var(--adm-spacing-md);
}

.adm-form-label {
  display: block;
  margin-bottom: var(--adm-spacing-xs);
  font-weight: var(--adm-font-weight-medium);
  color: var(--adm-text-color);
}

.adm-form-textarea {
  width: 100%;
  padding: var(--adm-spacing-sm);
  border: 1px solid var(--adm-border-color);
  border-radius: var(--adm-border-radius-sm);
  font-size: var(--adm-font-size-base);
  resize: vertical;
  background-color: var(--adm-bg-primary);
  color: var(--adm-text-color);
}

.adm-form-textarea:focus {
  outline: none;
  border-color: var(--adm-primary-color);
  box-shadow: 0 0 0 2px rgba(var(--adm-primary-color-rgb), 0.2);
}

@media (max-width: 768px) {
  .adm-filter-container {
    flex-direction: column;
  }

  .adm-form-input,
  .adm-form-select {
    width: 100%;
  }

  .adm-table {
    border: 0;
  }

  .adm-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .adm-table tr {
    border-bottom: 3px solid var(--adm-border-color);
    display: block;
    margin-bottom: var(--adm-spacing-md);
  }

  .adm-table td {
    border-bottom: 1px solid var(--adm-border-color);
    display: block;
    font-size: var(--adm-font-size-sm);
    text-align: right;
  }

  .adm-table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .adm-table td:last-child {
    border-bottom: 0;
  }

  .adm-pagination {
    flex-direction: column;
    align-items: stretch;
  }

  .adm-pagination-info {
    order: -1;
    margin-bottom: var(--adm-spacing-sm);
  }

  .adm-btn {
    width: 100%;
    margin-bottom: var(--adm-spacing-sm);
  }
}
</style>