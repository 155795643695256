<template>
  <div class="adm-footer">
    <h2 class="adm-section-title">Manage Footer Content</h2>
    <div v-if="footerStore.loading" class="adm-loading">
      <i class="bi bi-hourglass-split"></i> Loading...
    </div>
    <div v-if="footerStore.error" class="adm-error">
      <i class="bi bi-exclamation-triangle"></i> {{ footerStore.error }}
      <button @click="resetAndCreateNew" class="adm-btn adm-btn-primary">
        <i class="bi bi-plus-circle"></i> Create New Footer Data
      </button>
    </div>
    <form v-if="!footerStore.loading" @submit.prevent="saveFooter" class="adm-form">
      <div class="adm-form-group">
        <label for="ngo_name" class="adm-form-label">NGO Name:</label>
        <input id="ngo_name" v-model="editedFooter.ngo_name" required class="adm-form-input">
      </div>
      <div class="adm-form-group">
        <label for="about" class="adm-form-label">About:</label>
        <textarea id="about" v-model="editedFooter.about" required class="adm-form-textarea"></textarea>
      </div>
      <div class="adm-form-group">
        <h3 class="adm-form-subtitle">Quick Links</h3>
        <div v-for="(link, index) in editedFooter.quickLinks" :key="index" class="adm-input-group">
          <input v-model="link.text" placeholder="Link text" required class="adm-form-input">
          <input v-model="link.url" placeholder="Link URL" required class="adm-form-input">
          <button type="button" @click="removeQuickLink(index)" class="adm-btn adm-btn-danger adm-btn-sm">
            <i class="bi bi-trash"></i>
          </button>
        </div>
        <button type="button" @click="addQuickLink" class="adm-btn adm-btn-secondary">
          <i class="bi bi-plus-circle"></i> Add Quick Link
        </button>
      </div>
      <div class="adm-form-group">
        <label for="address" class="adm-form-label">Address:</label>
        <input id="address" v-model="editedFooter.address" required class="adm-form-input">
      </div>
      <div class="adm-form-group">
        <label for="email" class="adm-form-label">Email:</label>
        <input id="email" v-model="editedFooter.email" required class="adm-form-input">
      </div>
      <div class="adm-form-group">
        <label for="phone" class="adm-form-label">Phone:</label>
        <input id="phone" v-model="editedFooter.phone" required class="adm-form-input">
      </div>
      <div class="adm-form-group">
        <h3 class="adm-form-subtitle">Social Media Links</h3>
        <div v-for="(social, index) in editedFooter.socialMedia" :key="index" class="adm-input-group">
          <input v-model="social.platform" placeholder="Platform" required class="adm-form-input">
          <input v-model="social.url" placeholder="URL" required class="adm-form-input">
          <button type="button" @click="removeSocialMedia(index)" class="adm-btn adm-btn-danger adm-btn-sm">
            <i class="bi bi-trash"></i>
          </button>
        </div>
        <button type="button" @click="addSocialMedia" class="adm-btn adm-btn-secondary">
          <i class="bi bi-plus-circle"></i> Add Social Media
        </button>
      </div>
      <div class="adm-form-actions">
        <button type="submit" class="adm-btn adm-btn-primary">
          <i class="bi bi-save"></i> Save Footer
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useFooterStore } from '@/stores/footerStore';

export default {
  name: 'AdminFooter',
  setup() {
    const footerStore = useFooterStore();
    const editedFooter = ref(getEmptyFooterData());

    function getEmptyFooterData() {
      return {
        ngo_name: '',
        about: '',
        quickLinks: [],
        address: '',
        email: '',
        phone: '',
        socialMedia: []
      };
    }

    onMounted(async () => {
      try {
        await footerStore.fetchFooterData();
        if (footerStore.footerData) {
          editedFooter.value = JSON.parse(JSON.stringify(footerStore.footerData));
        } else {
          console.warn('No footer data returned from the server.');
        }
      } catch (error) {
        console.error('Error fetching footer data:', error);
      }
    });

    const resetAndCreateNew = () => {
      editedFooter.value = getEmptyFooterData();
      footerStore.error = null;
    };

    const addQuickLink = () => {
      editedFooter.value.quickLinks.push({ text: '', url: '' });
    };

    const removeQuickLink = (index) => {
      editedFooter.value.quickLinks.splice(index, 1);
    };

    const addSocialMedia = () => {
      editedFooter.value.socialMedia.push({ platform: '', url: '' });
    };

    const removeSocialMedia = (index) => {
      editedFooter.value.socialMedia.splice(index, 1);
    };

    const saveFooter = async () => {
      try {
        if (editedFooter.value.id) {
          await footerStore.updateFooterData(editedFooter.value);
        } else {
          await footerStore.createFooterData(editedFooter.value);
        }
        if (!footerStore.error) {
          alert('Footer ' + (editedFooter.value.id ? 'updated' : 'created') + ' successfully!');
        }
      } catch (error) {
        console.error('Error saving footer data:', error);
        alert('Failed to save footer data. Please try again.');
      }
    };

    return {
      footerStore,
      editedFooter,
      resetAndCreateNew,
      addQuickLink,
      removeQuickLink,
      addSocialMedia,
      removeSocialMedia,
      saveFooter
    };
  }
};
</script>

<style scoped>
.adm-footer {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--adm-spacing-md);
}

.adm-section-title {
  font-size: var(--adm-font-size-xl);
  margin-bottom: var(--adm-spacing-lg);
  color: var(--adm-primary-color);
  font-weight: var(--adm-font-weight-bold);
  text-align: center;
}

.adm-loading,
.adm-error {
  padding: var(--adm-spacing-md);
  border-radius: var(--adm-border-radius);
  margin-bottom: var(--adm-spacing-md);
  font-size: var(--adm-font-size-base);
}

.adm-loading {
  background-color: var(--adm-bg-secondary);
  color: var(--adm-text-color);
}

.adm-error {
  background-color: var(--adm-danger-color);
  color: var(--adm-bg-primary);
}

.adm-form {
  padding: var(--adm-spacing-lg);
  border-radius: var(--adm-border-radius);
  box-shadow: var(--adm-shadow);
  background-color: var(--adm-bg-primary);
}

.adm-form-group {
  margin-bottom: var(--adm-spacing-md);
}

.adm-form-label {
  display: block;
  margin-bottom: var(--adm-spacing-xs);
  font-weight: var(--adm-font-weight-medium);
  color: var(--adm-text-color);
}

.adm-form-input,
.adm-form-textarea {
  width: 100%;
  padding: var(--adm-spacing-sm);
  border: 1px solid var(--adm-border-color);
  border-radius: var(--adm-border-radius-sm);
  font-size: var(--adm-font-size-base);
  color: var(--adm-text-color);
  background-color: var(--adm-bg-primary);
  transition: var(--adm-transition);
}

.adm-form-input:focus,
.adm-form-textarea:focus {
  outline: none;
  border-color: var(--adm-primary-color);
  box-shadow: 0 0 0 2px rgba(var(--adm-primary-color-rgb), 0.2);
}

.adm-form-textarea {
  min-height: 100px;
  resize: vertical;
}

.adm-form-subtitle {
  font-size: var(--adm-font-size-lg);
  margin-bottom: var(--adm-spacing-sm);
  color: var(--adm-text-color);
  font-weight: var(--adm-font-weight-medium);
}

.adm-input-group {
  display: flex;
  margin-bottom: var(--adm-spacing-sm);
}

.adm-input-group .adm-form-input {
  flex-grow: 1;
  margin-right: var(--adm-spacing-sm);
}

.adm-btn {
  padding: var(--adm-spacing-sm) var(--adm-spacing-md);
  border: none;
  border-radius: var(--adm-border-radius-sm);
  cursor: pointer;
  font-size: var(--adm-font-size-base);
  font-weight: var(--adm-font-weight-medium);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: var(--adm-transition);
}

.adm-btn i {
  margin-right: var(--adm-spacing-xs);
}

.adm-btn-primary {
  background-color: var(--adm-primary-color);
  color: var(--adm-bg-primary);
}

.adm-btn-primary:hover,
.adm-btn-primary:focus {
  background-color: var(--adm-primary-color-dark);
}

.adm-btn-secondary {
  background-color: var(--adm-secondary-color);
  color: var(--adm-bg-primary);
}

.adm-btn-secondary:hover,
.adm-btn-secondary:focus {
  background-color: var(--adm-secondary-color-dark);
}

.adm-btn-danger {
  background-color: var(--adm-danger-color);
  color: var(--adm-bg-primary);
}

.adm-btn-danger:hover,
.adm-btn-danger:focus {
  background-color: var(--adm-danger-color-dark);
}

.adm-btn-sm {
  padding: var(--adm-spacing-xs) var(--adm-spacing-sm);
  font-size: var(--adm-font-size-sm);
}

.adm-form-actions {
  margin-top: var(--adm-spacing-lg);
  text-align: right;
}

@media (max-width: 768px) {
  .adm-input-group {
    flex-direction: column;
  }

  .adm-input-group .adm-form-input {
    margin-right: 0;
    margin-bottom: var(--adm-spacing-sm);
  }

  .adm-btn {
    width: 100%;
    margin-bottom: var(--adm-spacing-sm);
  }

  .adm-form-actions {
    text-align: center;
  }
}
</style>