export const adminSections = [
    { id: 'mainOverview', name: 'Dashboard', icon: 'bi bi-speedometer2' },            // Dashboard - Speedometer icon
    { id: 'allMedia', name: 'Media', icon: 'bi bi-collection-play' },                 // Media - Collection play icon
    { id: 'aboutUs', name: 'About Us', icon: 'bi bi-info-square' },                   // About Us - Info square icon
    { id: 'profile', name: 'Profile', icon: 'bi bi-person-badge' },                   // Profile - Person badge icon
    { id: 'ProjectSlider', name: 'Project Slider', icon: 'bi bi-image-alt' },          // Project Slider - Image alt icon
    { id: 'upcomingProjects', name: 'Upcoming Project', icon: 'bi bi-calendar-event' },// Upcoming Projects - Calendar event icon
    { id: 'adminFooter', name: 'Footer Settings', icon: 'bi bi-layout-footer' },       // Footer Settings - Layout footer icon
    { id: 'HeroSliderAdmin', name: 'Hero Slider', icon: 'bi bi-sliders2-vertical' },   // Hero Slider - Sliders vertical icon
    { id: 'AdminDonationSettings', name: 'Donation Settings', icon: 'bi bi-cash-stack' }, // Donation Settings - Cash stack icon
    { id: 'donorsManagement', name: 'Donors', icon: 'bi bi-people-fill' }              // Donors - People fill icon
];
