<template>
  <div class="adm-about-admin">
    <h2 class="adm-section-title">User Profile</h2>
    <form @submit.prevent="updateProfile" class="adm-form">
      <div class="adm-form-group">
        <label for="name" class="adm-form-label">Name:</label>
        <input
          type="text"
          id="name"
          v-model="userData.name"
          required
          class="adm-form-input"
        />
      </div>
      <div class="adm-form-group">
        <label for="email" class="adm-form-label">Email:</label>
        <input
          type="email"
          id="email"
          v-model="userData.email"
          required
          class="adm-form-input"
        />
      </div>
      <div class="adm-form-group">
        <label for="password" class="adm-form-label">
          New Password (leave blank to keep current):
        </label>
        <input
          type="password"
          id="password"
          v-model="newPassword"
          class="adm-form-input"
        />
      </div>
      <div class="adm-form-actions">
        <button type="submit" class="adm-btn adm-btn-primary" :disabled="isLoading">
          <i class="bi bi-check-circle"></i>
          <span v-if="isLoading">Updating...</span>
          <span v-else>Update Profile</span>
        </button>
      </div>
      <p v-if="updateMessage" class="adm-update-message">{{ updateMessage }}</p>
    </form>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import { useAuthStore } from '@/stores/authStore';

export default {
  setup() {
    const authStore = useAuthStore();
    const userData = reactive({
      name: '',
      email: '',
    });
    const newPassword = ref('');
    const isLoading = ref(false);
    const updateMessage = ref('');

    onMounted(() => {
      // Initialize the form with the current user data
      userData.name = authStore.user?.name || '';
      userData.email = authStore.user?.email || '';
    });

    const updateProfile = async () => {
      isLoading.value = true;
      updateMessage.value = '';

      try {
        const updatedUserData = { ...userData };
        if (newPassword.value) {
          updatedUserData.password = newPassword.value;
        }

        await authStore.updateUserProfile(updatedUserData);
        updateMessage.value = 'Profile updated successfully';
        newPassword.value = ''; // Clear the password field after successful update
      } catch (error) {
        updateMessage.value = authStore.error || 'Failed to update profile';
      } finally {
        isLoading.value = false;
      }
    };

    return {
      userData,
      newPassword,
      updateProfile,
      isLoading,
      updateMessage,
    };
  },
};
</script>


<style scoped>
.adm-update-message {
  color: green; /* Or any style you prefer for success messages */
}
</style>

<style scoped>
.adm-about-admin {
  max-width: 600px;
  margin: 0 auto;
  padding: var(--adm-spacing-md);
}

.adm-section-title {
  font-size: var(--adm-font-size-xl);
  margin-bottom: var(--adm-spacing-lg);
  color: var(--adm-primary-color);
  font-weight: var(--adm-font-weight-bold);
  text-align: center;
}

.adm-form {
  background-color: var(--adm-bg-primary);
  padding: var(--adm-spacing-lg);
  border-radius: var(--adm-border-radius);
  box-shadow: var(--adm-shadow);
}

.adm-form-group {
  margin-bottom: var(--adm-spacing-lg);
}

.adm-form-label {
  display: block;
  margin-bottom: var(--adm-spacing-xs);
  font-weight: var(--adm-font-weight-bold);
  color: var(--adm-text-color);
}

.adm-form-input {
  width: 100%;
  padding: var(--adm-spacing-sm);
  border: 1px solid var(--adm-border-color);
  border-radius: var(--adm-border-radius-sm);
  font-size: var(--adm-font-size-base);
  color: var(--adm-text-color);
  background-color: var(--adm-bg-primary);
  transition: var(--adm-transition);
}

.adm-form-input:focus {
  outline: none;
  border-color: var(--adm-primary-color);
  box-shadow: 0 0 0 2px rgba(var(--adm-primary-color-rgb), 0.2);
}

.adm-btn {
  padding: var(--adm-spacing-sm) var(--adm-spacing-md);
  border: none;
  border-radius: var(--adm-border-radius-sm);
  cursor: pointer;
  font-size: var(--adm-font-size-base);
  font-weight: var(--adm-font-weight-medium);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: var(--adm-transition);
  text-decoration: none;
}

.adm-btn i {
  margin-right: var(--adm-spacing-xs);
}

.adm-btn-primary {
  background-color: var(--adm-primary-color);
  color: var(--adm-bg-primary);
}

.adm-btn-primary:hover,
.adm-btn-primary:focus {
  background-color: var(--adm-primary-color-dark);
}

.adm-form-actions {
  margin-top: var(--adm-spacing-xl);
  text-align: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .adm-about-admin {
    padding: var(--adm-spacing-sm);
  }

  .adm-form {
    padding: var(--adm-spacing-md);
  }

  .adm-section-title {
    font-size: var(--adm-font-size-lg);
  }
}
</style>