<template>
  <section id="upcoming" class="upcoming-projects container">
    <h2>Upcoming Projects</h2>
    <div v-if="upcomingProjectsStore.loading">Loading...</div>
    <div v-else-if="upcomingProjectsStore.error">{{ upcomingProjectsStore.error }}</div>
    <div v-else class="project-grid">
      <div v-for="project in upcomingProjectsStore.upcomingProjects" :key="project.id" class="project-card">
        <h3>{{ project.name }}</h3>
        <p class="description">{{ project.description }}</p>
        <div class="funding-info">
          <div class="funding-item">
            <span class="label">Needed:</span>
            <span class="value">${{ project.funding_needed.toLocaleString() }}</span>
          </div>
          <div class="funding-item">
            <span class="label">Received:</span>
            <span class="value">${{ project.funding_received.toLocaleString() }}</span>
          </div>
          <div class="funding-item">
            <span class="label">Remaining:</span>
            <span class="value">${{ (project.funding_needed - project.funding_received).toLocaleString() }}</span>
          </div>
        </div>
        <div class="progress-bar">
          <div class="progress" :style="{ width: `${(project.funding_received / project.funding_needed) * 100}%` }"></div>
        </div>
        <button @click="$emit('donate', project.id)" class="donate-btn">Donate to this project</button>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted } from 'vue';
import { useUpcomingProjectsStore } from '@/stores/upcomingProjectsStore';

export default {
  name: 'UpcomingProjects',
  emits: ['donate'],
  setup() {
    const upcomingProjectsStore = useUpcomingProjectsStore();

    onMounted(() => {
      upcomingProjectsStore.fetchUpcomingProjects();
    });

    return {
      upcomingProjectsStore
    };
  }
}
</script>

<style scoped>
.upcoming-projects {
  padding: 2rem;
  background-color: var(--light-bg-secondary); /* Light background */
  color: var(--light-text-primary); /* Light text */
}

.dark-theme .upcoming-projects {
  background-color: var(--dark-bg-secondary); /* Dark background */
  color: var(--dark-text-primary); /* Dark text */
}

h2 {
  color: var(--light-accent-color); /* Light accent color */
  margin-bottom: 2rem;
  text-align: center;
  font-size: 2.5rem;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
}

.project-card {
  background-color: var(--light-bg-primary); /* Light primary background */
  padding: 1.5rem;
  border-radius: var(--border-radius); /* Border radius variable */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.description {
  margin-bottom: 1.5rem;
  flex-grow: 1;
}

.funding-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.funding-item {
  text-align: center;
}

.label {
  display: block;
  color: var(--light-text-secondary); /* Light secondary text */
  margin-bottom: 0.25rem;
}

.value {
  font-weight: bold;
}

.progress-bar {
  width: 100%;
  height: 8px;
  background-color: var(--light-border-color); /* Light border color */
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 1rem;
}

.progress {
  height: 100%;
  background-color: var(--light-accent-color); /* Light accent color */
  transition: width 0.3s ease-in-out;
}

.donate-btn {
  padding: 0.75rem 1rem;
  color: white;
  background-color: var(--light-accent-color); /* Light accent color */
  border: none;
  border-radius: var(--border-radius); /* Border radius variable */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.donate-btn:hover {
  background-color: var(--light-hover-color);
  transform: translateY(-2px);
}

h3 {
  color: var(--light-accent-color); /* Light accent color */
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.dark-theme h2,
.dark-theme h3 {
  color: var(--dark-accent-color); /* Dark accent color for headings */
}

.dark-theme .project-card {
  background-color: var(--dark-bg-primary); /* Dark primary background */
  box-shadow: 0 2px 4px rgba(255,255,255,0.1);
}

.dark-theme .progress-bar {
  background-color: var(--dark-bg-secondary); /* Dark secondary background */
}

.dark-theme .progress {
  background-color: var(--dark-accent-color); /* Dark accent color for progress */
}

.dark-theme .donate-btn {
  background-color: var(--dark-accent-color); /* Dark accent color for donate button */
}

.donate-btn:active {
  transform: translateY(0);
}

@media (max-width: 768px) {
  .funding-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .funding-item {
    margin-bottom: 0.5rem;
    text-align: left;
  }

  .label {
    display: inline-block;
    width: 80px;
  }
}
</style>
