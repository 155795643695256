<template>
  <nav class="bottom-nav">
    <a href="#about" class="bottom-nav-item">
      <i class="bi bi-info-circle"></i>
      <span>About</span>
    </a>
    <a href="#projects" class="bottom-nav-item">
      <i class="bi bi-grid"></i>
      <span>Projects</span>
    </a>
    <a href="#upcoming" class="bottom-nav-item upcoming">
      <i class="bi bi-calendar-event"></i>
      <span>Fund Us <i class="bi bi-hearts"></i></span>
    </a>
    <a href="#" @click.prevent="$emit('open-donate-modal')" class="bottom-nav-item">
      <i class="bi bi-heart"></i>
      <span>Donate</span>
    </a>
  </nav>
</template>

<script>
export default {
  name: 'BottomNavigation',
  emits: ['open-donate-modal']
}
</script>

<style scoped>
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  background-color: var(--light-bg-primary);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: background-color var(--transition-speed);
}

.dark-theme .bottom-nav {
  background-color: var(--dark-bg-primary);
  box-shadow: 0 -2px 10px rgba(255, 255, 255, 0.1);
}

.bottom-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: var(--light-text-primary);
  text-decoration: none;
  font-size: 0.8rem;
  cursor: pointer;
  transition: color var(--transition-speed);
}

.dark-theme .bottom-nav-item {
  color: var(--dark-text-primary);
}

.bottom-nav-item i {
  font-size: 1.2rem;
  margin-bottom: 4px;
}

.bottom-nav-item:hover {
  color: var(--light-accent-color);
}

.dark-theme .bottom-nav-item:hover {
  color: var(--dark-accent-color);
}

.upcoming {
  color: var(--light-accent-color) !important;
  font-weight: 800;
  font-style: normal;
}

.dark-theme .upcoming {
  color: var(--dark-accent-color) !important;
}

@media (min-width: 768px) {
  .bottom-nav {
    display: none;
  }
}
</style>