<template>
  <div class="thank-you-page" :class="{ 'dark-theme': isDarkTheme }">
    <div class="content">
      <h1 class="title">Thank You for Your Generosity!</h1>
      <div class="donation-impact">
        <div class="heart-icon"></div>
        <p>Your donation makes a real difference in people's lives.</p>
      </div>
      <div class="quote-container">
        <blockquote>
          <p>"{{ quote.text }}"</p>
          <footer>- {{ quote.author }}</footer>
        </blockquote>
      </div>
      <div class="countdown">
        <p>Returning to home page in {{ countdown }} seconds</p>
        <div class="progress-bar" :style="{ width: `${(countdown / 10) * 100}%` }"></div>
      </div>
      <button @click="goHome" class="home-button">
        <i class="bi bi-house-door"></i> Go to Home Page
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'

const quotes = [
  { text: "The meaning of life is to find your gift. The purpose of life is to give it away.", author: "Pablo Picasso" },
  { text: "We make a living by what we get, but we make a life by what we give.", author: "Winston Churchill" },
  { text: "No one has ever become poor by giving.", author: "Anne Frank" },
  { text: "The greatest good you can do for another is not just to share your riches but to reveal to him his own.", author: "Benjamin Disraeli" },
  { text: "Giving is not just about making a donation. It is about making a difference.", author: "Kathy Calvin" },
  { text: "The best way to find yourself is to lose yourself in the service of others.", author: "Mahatma Gandhi" },
  { text: "Alone we can do so little; together we can do so much.", author: "Helen Keller" },
  { text: "Kindness in words creates confidence. Kindness in thinking creates profoundness. Kindness in giving creates love.", author: "Lao Tzu" }
]

export default {
  name: 'ThankYouPage',
  props: {
    isDarkTheme: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const router = useRouter()
    const countdown = ref(10)
    const quote = ref(quotes[Math.floor(Math.random() * quotes.length)])
    let timer

    const goHome = () => {
      router.push('/')
    }

    onMounted(() => {
      timer = setInterval(() => {
        countdown.value--
        if (countdown.value === 0) {
          clearInterval(timer)
          goHome()
        }
      }, 1000)
    })

    onUnmounted(() => {
      clearInterval(timer)
    })

    return {
      countdown,
      goHome,
      quote
    }
  }
}
</script>

<style scoped>
.thank-you-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: var(--light-bg-secondary);
  font-family: 'Arial', sans-serif;
  transition: background-color var(--transition-speed);
}

.thank-you-page.dark-theme {
  background: var(--dark-bg-secondary);
}

.content {
  background: var(--light-bg-primary);
  color: var(--light-text-primary);
  padding: 3rem;
  border-radius: var(--border-radius);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 600px;
  width: 90%;
  transition: background-color var(--transition-speed), color var(--transition-speed);
}

.dark-theme .content {
  background: var(--dark-bg-primary);
  color: var(--dark-text-primary);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.title {
  color: var(--light-accent-color);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  transition: color var(--transition-speed);
}

.dark-theme .title {
  color: var(--dark-accent-color);
}

.donation-impact {
  margin-bottom: 2rem;
}

.heart-icon {
  width: 60px;
  height: 60px;
  background: var(--light-accent-color);
  display: inline-block;
  transform: rotate(-45deg);
  margin-bottom: 1rem;
  position: relative;
  animation: pulse 1.5s infinite;
}

.heart-icon::before,
.heart-icon::after {
  content: "";
  width: 60px;
  height: 60px;
  background: var(--light-accent-color);
  border-radius: 50%;
  position: absolute;
}

.heart-icon::before {
  top: -30px;
  left: 0;
}

.heart-icon::after {
  top: 0;
  left: 30px;
}

.dark-theme .heart-icon,
.dark-theme .heart-icon::before,
.dark-theme .heart-icon::after {
  background: var(--dark-accent-color);
}

@keyframes pulse {
  0% { transform: rotate(-45deg) scale(1); }
  50% { transform: rotate(-45deg) scale(1.1); }
  100% { transform: rotate(-45deg) scale(1); }
}

.quote-container {
  background: var(--light-bg-secondary);
  border-left: 5px solid var(--light-accent-color);
  margin: 1.5rem 0;
  padding: 1rem;
  text-align: left;
  transition: background-color var(--transition-speed), border-color var(--transition-speed);
}

.dark-theme .quote-container {
  background: var(--dark-bg-secondary);
  border-left-color: var(--dark-accent-color);
}

blockquote p {
  font-style: italic;
  font-size: 1.1rem;
  line-height: 1.6;
}

blockquote footer {
  margin-top: 0.5rem;
  font-weight: bold;
  color: var(--light-text-secondary);
  transition: color var(--transition-speed);
}

.dark-theme blockquote footer {
  color: var(--dark-text-secondary);
}

.countdown {
  margin: 2rem 0;
}

.progress-bar {
  height: 4px;
  background-color: var(--light-accent-color);
  transition: width 1s linear, background-color var(--transition-speed);
}

.dark-theme .progress-bar {
  background-color: var(--dark-accent-color);
}

.home-button {
  background-color: var(--light-accent-color);
  color: var(--light-bg-primary);
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color var(--transition-speed), transform 0.2s;
}

.dark-theme .home-button {
  background-color: var(--dark-accent-color);
  color: var(--dark-bg-primary);
}

.home-button:hover {
  background-color: var(--light-hover-color);
  transform: translateY(-2px);
}

.dark-theme .home-button:hover {
  background-color: var(--dark-hover-color);
}

@media (max-width: 480px) {
  .content {
    padding: 2rem;
  }

  .title {
    font-size: 2rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .heart-icon,
  .home-button,
  .progress-bar {
    animation: none;
    transition: none;
  }
}
</style>