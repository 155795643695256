// src/stores/dashboardStore.js
import { defineStore } from 'pinia';
import api from '@/axios/api.js';

export const useDashboardStore = defineStore('dashboard', {
  state: () => ({
    totalDonations: 0,
    totalDonors: 0,
    projects: [],
    oneTimeDonationsCount: 0,
    monthlyDonationsCount: 0,
    monthlyTrend: []
  }),
  actions: {
    async fetchDashboardData() {
      try {
        const response = await api.get('/dashboard-summary');
        this.totalDonations = response.data.totalDonations;
        this.totalDonors = response.data.totalDonors;
        this.projects = response.data.projects;
        this.oneTimeDonationsCount = response.data.oneTimeDonationsCount;
        this.monthlyDonationsCount = response.data.monthlyDonationsCount;
        this.monthlyTrend = response.data.monthlyTrend;
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    }
  }
});