import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from '@/stores/authStore';

import LoginRegister from "@/view/LoginRegister.vue";
import AdminDashboard from "@/studio/AdminDashboard.vue";
import HomePage from '@/view/homePage.vue';
import ThankYouPage from '@/view/ThankYouPage.vue';

const routes = [
  { path: "/", name: "HomePage", component: HomePage },
  { path: "/studio-login", name: "LoginRegister", component: LoginRegister },
  { path: "/thank-you", name: "ThankYouPage", component: ThankYouPage },
  
  // Admin Dashboard (protected route)
  {
    path: "/studio-admin",
    name: "AdminDashboard",
    component: AdminDashboard,
    meta: { requiresAuth: true, hideNavbar: true, hideFooter: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Add a global navigation guard
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();

  // Check if the route requires authentication
  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    // If not authenticated, redirect to login
    return next({ name: 'LoginRegister' });
  }

  // If the user is already authenticated and tries to access login, redirect to dashboard
  if (to.name === 'LoginRegister' && authStore.isAuthenticated) {
    return next({ name: 'AdminDashboard' });
  }

  // Proceed as normal
  next();
});

export default router;
