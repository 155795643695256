// stores/upcomingProjectsStore.js
import { defineStore } from 'pinia';
import api from '@/axios/api.js';

export const useUpcomingProjectsStore = defineStore('upcomingProjects', {
  state: () => ({
    upcomingProjects: [],
    loading: false,
    error: null
  }),
  actions: {
    async fetchUpcomingProjects() {
      this.loading = true;
      try {
        const response = await api.get('/upcoming-projects');
        this.upcomingProjects = response.data;
        this.error = null;
      } catch (error) {
        console.error('Error fetching upcoming projects:', error);
        this.error = 'Failed to fetch upcoming projects';
      } finally {
        this.loading = false;
      }
    },
    async createUpcomingProject(projectData) {
      this.loading = true;
      try {
        const response = await api.post('/upcoming-projects', projectData);
        this.upcomingProjects.push(response.data);
        this.error = null;
      } catch (error) {
        console.error('Error creating upcoming project:', error);
        this.error = 'Failed to create upcoming project';
      } finally {
        this.loading = false;
      }
    },
    async updateUpcomingProject(projectData) {
      this.loading = true;
      try {
        const response = await api.put(`/upcoming-projects/${projectData.id}`, projectData);
        const index = this.upcomingProjects.findIndex(p => p.id === projectData.id);
        if (index !== -1) {
          this.upcomingProjects[index] = response.data;
        }
        this.error = null;
      } catch (error) {
        console.error('Error updating upcoming project:', error);
        this.error = 'Failed to update upcoming project';
      } finally {
        this.loading = false;
      }
    },
    async deleteUpcomingProject(id) {
      this.loading = true;
      try {
        await api.delete(`/upcoming-projects/${id}`);
        this.upcomingProjects = this.upcomingProjects.filter(p => p.id !== id);
        this.error = null;
      } catch (error) {
        console.error('Error deleting upcoming project:', error);
        this.error = 'Failed to delete upcoming project';
      } finally {
        this.loading = false;
      }
    }
  }
});