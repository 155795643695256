<template>
  <div class="adm-donation-settings">
    <h2 class="adm-section-title">Donation Settings</h2>

    <div class="adm-card">
      <div class="adm-card-body">
        <h3 class="adm-card-title">Accepted Items</h3>
        <ul class="adm-list">
          <li v-for="item in donationStore.acceptedItems" :key="item" class="adm-list-item">
            <span>{{ item }}</span>
            <button @click="removeAcceptedItem(item)" class="adm-btn adm-btn-danger adm-btn-sm">
              <i class="bi bi-trash"></i> Remove
            </button>
          </li>
        </ul>
        <div class="adm-form-group adm-flex-row">
          <input v-model="newItem" placeholder="New item" class="adm-form-input">
          <button @click="addAcceptedItem" class="adm-btn adm-btn-primary">
            <i class="bi bi-plus-circle"></i> Add Item
          </button>
        </div>
      </div>
    </div>

    <div class="adm-card adm-mt-md">
      <div class="adm-card-body">
        <h3 class="adm-card-title">NGO Address</h3>
        <div class="adm-form-group">
          <textarea v-model="ngoAddress" rows="3" class="adm-form-textarea"></textarea>
          <button @click="updateNGOAddress" class="adm-btn adm-btn-primary adm-mt-sm">
            <i class="bi bi-pencil-square"></i> Update Address
          </button>
        </div>
      </div>
    </div>

    <div class="adm-card adm-mt-md">
      <div class="adm-card-body">
        <h3 class="adm-card-title">Payment Methods</h3>
        <ul class="adm-list">
          <li v-for="method in donationStore.paymentMethods" :key="method.id" class="adm-list-item">
            <div class="adm-payment-info">
              <span class="adm-payment-name">{{ method.name }}</span>
              <span class="adm-payment-link">{{ method.link }}</span>
            </div>
            <button @click="removePaymentMethod(method.id)" class="adm-btn adm-btn-danger adm-btn-sm">
              <i class="bi bi-trash"></i> Remove
            </button>
          </li>
        </ul>
        <div class="adm-form-group">
          <input v-model="newMethodName" placeholder="Method name" class="adm-form-input">
          <input v-model="newMethodLink" placeholder="Method link" class="adm-form-input adm-mt-sm">
          <button @click="addPaymentMethod" class="adm-btn adm-btn-primary adm-mt-sm">
            <i class="bi bi-plus-circle"></i> Add Payment Method
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useDonationStore } from '@/stores/donationSettings'

export default {
  name: 'AdminDonationSettings',
  setup() {
    const donationStore = useDonationStore()

    const newItem = ref('')
    const ngoAddress = ref('')
    const newMethodName = ref('')
    const newMethodLink = ref('')

    onMounted(async () => {
      await donationStore.fetchSettings()
      ngoAddress.value = donationStore.ngoAddress
    })

    const addAcceptedItem = async () => {
      if (newItem.value) {
        try {
          await donationStore.addAcceptedItem(newItem.value)
          newItem.value = ''
        } catch (error) {
          alert('Error adding item')
        }
      }
    }

    const removeAcceptedItem = async (item) => {
      try {
        await donationStore.removeAcceptedItem(item)
      } catch (error) {
        alert('Error removing item')
      }
    }

    const updateNGOAddress = async () => {
      try {
        await donationStore.updateNGOAddress(ngoAddress.value)
        alert('NGO address updated successfully')
      } catch (error) {
        alert('Error updating NGO address')
      }
    }

    const addPaymentMethod = async () => {
      if (newMethodName.value && newMethodLink.value) {
        try {
          await donationStore.addPaymentMethod({
            id: Date.now(),
            name: newMethodName.value,
            link: newMethodLink.value
          })
          newMethodName.value = ''
          newMethodLink.value = ''
        } catch (error) {
          alert('Error adding payment method')
        }
      }
    }

    const removePaymentMethod = async (id) => {
      try {
        await donationStore.removePaymentMethod(id)
      } catch (error) {
        alert('Error removing payment method')
      }
    }

    return {
      donationStore,
      newItem,
      ngoAddress,
      newMethodName,
      newMethodLink,
      addAcceptedItem,
      removeAcceptedItem,
      updateNGOAddress,
      addPaymentMethod,
      removePaymentMethod
    }
  }
}
</script>

<style scoped>
.adm-donation-settings {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--adm-spacing-md);
}

.adm-section-title {
  font-size: var(--adm-font-size-xl);
  margin-bottom: var(--adm-spacing-lg);
  color: var(--adm-primary-color);
  font-weight: var(--adm-font-weight-bold);
  text-align: center;
}

.adm-card {
  background-color: var(--adm-bg-primary);
  border-radius: var(--adm-border-radius);
  box-shadow: var(--adm-shadow);
  transition: var(--adm-transition);
  overflow: hidden;
}

.adm-card-body {
  padding: var(--adm-spacing-lg);
}

.adm-card-title {
  font-size: var(--adm-font-size-lg);
  color: var(--adm-text-color);
  font-weight: var(--adm-font-weight-bold);
  margin-bottom: var(--adm-spacing-md);
}

.adm-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: var(--adm-spacing-md);
}

.adm-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--adm-spacing-sm);
  border-bottom: 1px solid var(--adm-border-color);
}

.adm-list-item:last-child {
  border-bottom: none;
}

.adm-payment-info {
  display: flex;
  flex-direction: column;
}

.adm-payment-name {
  font-weight: var(--adm-font-weight-medium);
}

.adm-payment-link {
  font-size: var(--adm-font-size-sm);
  color: var(--adm-text-color-light);
}

.adm-form-group {
  margin-bottom: var(--adm-spacing-md);
}

.adm-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--adm-spacing-sm);
}

.adm-form-input,
.adm-form-textarea {
  width: 100%;
  padding: var(--adm-spacing-sm);
  border: 1px solid var(--adm-border-color);
  border-radius: var(--adm-border-radius-sm);
  font-size: var(--adm-font-size-base);
  color: var(--adm-text-color);
  background-color: var(--adm-bg-primary);
  transition: var(--adm-transition);
}

.adm-form-input:focus,
.adm-form-textarea:focus {
  outline: none;
  border-color: var(--adm-primary-color);
  box-shadow: 0 0 0 2px rgba(var(--adm-primary-color-rgb), 0.2);
}

.adm-form-textarea {
  min-height: 100px;
  resize: vertical;
}

.adm-btn {
  padding: var(--adm-spacing-sm) var(--adm-spacing-md);
  border: none;
  border-radius: var(--adm-border-radius-sm);
  cursor: pointer;
  font-size: var(--adm-font-size-base);
  font-weight: var(--adm-font-weight-medium);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: var(--adm-transition);
}

.adm-btn i {
  margin-right: var(--adm-spacing-xs);
}

.adm-btn-primary {
  background-color: var(--adm-primary-color);
  color: var(--adm-bg-primary);
}

.adm-btn-primary:hover,
.adm-btn-primary:focus {
  background-color: var(--adm-primary-color-dark);
}

.adm-btn-danger {
  background-color: var(--adm-danger-color);
  color: var(--adm-bg-primary);
}

.adm-btn-danger:hover,
.adm-btn-danger:focus {
  background-color: var(--adm-danger-color-dark);
}

.adm-btn-sm {
  padding: var(--adm-spacing-xs) var(--adm-spacing-sm);
  font-size: var(--adm-font-size-sm);
}

.adm-mt-sm {
  margin-top: var(--adm-spacing-sm);
}

.adm-mt-md {
  margin-top: var(--adm-spacing-md);
}

@media (max-width: 768px) {
  .adm-donation-settings {
    padding: var(--adm-spacing-sm);
  }

  .adm-section-title {
    font-size: var(--adm-font-size-lg);
  }

  .adm-card-body {
    padding: var(--adm-spacing-md);
  }

  .adm-flex-row {
    flex-direction: column;
  }

  .adm-btn {
    width: 100%;
  }

  .adm-list-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .adm-payment-info {
    margin-bottom: var(--adm-spacing-sm);
  }

  .adm-btn-sm {
    width: auto;
    align-self: flex-end;
  }
}
</style>