<template>
  <section id="projects" class="projects-slider container" :class="{ 'dark-theme': isDarkTheme }">
    <h2>Our Projects</h2>
    <div class="slider-container">
      <button class="nav-button prev" @click="scrollSlider('left')">&lt;</button>
      <div class="slider" ref="slider">
        <div v-for="project in projectStore.projects" :key="project.id" class="slide">
          <div class="image-container">
            <img :src="project.image_url" :alt="project.name">
          </div>
          <div class="content">
            <h3>{{ project.name }}</h3>
            <p>{{ project.short_description }}</p>
            <button class="learn-more" @click="openModal(project)">Learn More</button>
          </div>
        </div>
      </div>
      <button class="nav-button next" @click="scrollSlider('right')">&gt;</button>
    </div>

    <!-- Popup Modal -->
    <div v-if="selectedProject" class="modal" @click="closeModal">
      <div class="modal-content" @click.stop>
        <button class="close-button" @click="closeModal">&times;</button>
        <h2>{{ selectedProject.name }}</h2>
        <img :src="selectedProject.image_url" :alt="selectedProject.name" class="modal-image">
        <div class="modal-description">{{ selectedProject.long_description }}</div>
        <div v-if="selectedProject.achievements" class="achievements">
          <h3>Achievements</h3>
          <ul>
            <li v-for="(achievement, index) in selectedProject.achievements" :key="index">
              {{ achievement }}
            </li>
          </ul>
        </div>
        <div v-if="selectedProject.future_goals" class="future-goals">
          <h3>Future Goals</h3>
          <p>{{ selectedProject.future_goals }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useProjectStore } from '@/stores/projectSliderStore';

export default {
  name: 'ProjectsSlider',
  props: {
    isDarkTheme: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const projectStore = useProjectStore();
    return { projectStore };
  },
  data() {
    return {
      selectedProject: null
    }
  },
  methods: {
    scrollSlider(direction) {
      const slider = this.$refs.slider;
      const scrollAmount = slider.clientWidth;
      if (direction === 'left') {
        slider.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
      } else {
        slider.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      }
    },
    openModal(project) {
      this.selectedProject = project;
      document.body.style.overflow = 'hidden';
    },
    closeModal() {
      this.selectedProject = null;
      document.body.style.overflow = '';
    }
  },
  mounted() {
    this.projectStore.fetchProjects();
  }
}
</script>

<style scoped>
.projects-slider {
  padding: 2rem;
  background-color: var(--light-bg-secondary);
  color: var(--light-text-primary);
  transition: background-color var(--transition-speed), color var(--transition-speed);
}

.projects-slider.dark-theme {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
}

h2 {
  color: var(--light-accent-color);
  margin-bottom: 2rem;
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  transition: color var(--transition-speed);
}

.dark-theme h2 {
  color: var(--dark-accent-color);
}

.slider-container {
  position: relative;
}

.slider {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  gap: 2rem;
  padding: 1rem 0;
}

.slider::-webkit-scrollbar {
  display: none;
}

.slide {
  flex: 0 0 calc(33.333% - 1.33rem);
  scroll-snap-align: start;
  background-color: var(--light-bg-primary);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color var(--transition-speed);
}

.dark-theme .slide {
  background-color: var(--dark-bg-primary);
}

.slide:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.image-container {
  height: 200px;
  overflow: hidden;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.slide:hover img {
  transform: scale(1.1);
}

.content {
  padding: 1.5rem;
}

.slide h3 {
  color: var(--light-accent-color);
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  transition: color var(--transition-speed);
}

.dark-theme .slide h3 {
  color: var(--dark-accent-color);
}

.slide p {
  margin-bottom: 1rem;
  color: var(--light-text-secondary);
  font-size: 0.9rem;
  transition: color var(--transition-speed);
}

.dark-theme .slide p {
  color: var(--dark-text-secondary);
}

.learn-more {
  background-color: var(--light-accent-color);
  color: var(--light-bg-primary);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color var(--transition-speed), color var(--transition-speed);
}

.dark-theme .learn-more {
  background-color: var(--dark-accent-color);
  color: var(--dark-bg-primary);
}

.learn-more:hover {
  background-color: var(--light-hover-color);
}

.dark-theme .learn-more:hover {
  background-color: var(--dark-hover-color);
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--light-accent-color);
  color: var(--light-bg-primary);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color var(--transition-speed), color var(--transition-speed);
  z-index: 10;
}

.dark-theme .nav-button {
  background-color: var(--dark-accent-color);
  color: var(--dark-bg-primary);
}

.nav-button:hover {
  background-color: var(--light-hover-color);
}

.dark-theme .nav-button:hover {
  background-color: var(--dark-hover-color);
}

.nav-button.prev {
  left: -20px;
}

.nav-button.next {
  right: -20px;
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: var(--light-bg-primary);
  color: var(--light-text-primary);
  padding: 3rem;
  border-radius: var(--border-radius);
  width: 90%;
  max-width: 1000px;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  transition: background-color var(--transition-speed), color var(--transition-speed);
}

.dark-theme .modal-content {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-primary);
}

.close-button {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  font-size: 2rem;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--light-text-primary);
  transition: color var(--transition-speed);
}

.dark-theme .close-button {
  color: var(--dark-text-primary);
}

.modal-image {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: var(--border-radius);
  margin-bottom: 2rem;
}

.modal-description {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.achievements, .future-goals {
  margin-top: 2rem;
}

.achievements h3, .future-goals h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--light-accent-color);
  transition: color var(--transition-speed);
}

.dark-theme .achievements h3, 
.dark-theme .future-goals h3 {
  color: var(--dark-accent-color);
}

.achievements ul {
  list-style-type: none;
  padding-left: 0;
}

.achievements li {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.achievements li::before {
  content: '✓';
  color: var(--light-accent-color);
  margin-right: 0.75rem;
  font-weight: bold;
  transition: color var(--transition-speed);
}

.dark-theme .achievements li::before {
  color: var(--dark-accent-color);
}

@media (max-width: 1024px) {
  .slide {
    flex: 0 0 calc(50% - 1rem);
  }
}

@media (max-width: 768px) {
  .slide {
    flex: 0 0 calc(100% - 2rem);
  }
  
  .nav-button {
    display: none;
  }

  .modal-content {
    padding: 2rem;
    width: 95%;
  }

  .modal-image {
    max-height: 300px;
  }

  .modal-description {
    font-size: 1rem;
  }

  .achievements h3, .future-goals h3 {
    font-size: 1.3rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .projects-slider,
  .slide,
  .slide img,
  .learn-more,
  .nav-button,
  .modal-content,
  .close-button {
    transition: none;
  }
}
</style>