<template>
  <div class="home-page">
    <div v-if="isLoading" class="preloader">
      <div class="preloader-content">
        <div class="logo-container">
          <span class="logo-text">Free my</span>
          <div class="globe">
            <div class="globe-sphere"></div>
            <div class="globe-equator"></div>
            <div class="globe-meridian"></div>
          </div>
          <span class="logo-text">World</span>
        </div>
        <div class="loading-text">
          <span>L</span><span>o</span><span>a</span><span>d</span><span>i</span><span>n</span><span>g</span>
        </div>
      </div>
    </div>
    
    <template v-else>
      <AppHeader 
        :ngoName="ngoName" 
        :isDarkTheme="isDarkTheme" 
        @toggle-theme="$emit('toggle-theme')"
        @open-donate-modal="openDonateModal"
      />
      
      <main>
        <HeroSection :heroImages="heroImages" />
        <ProjectsSlider :isDarkTheme="isDarkTheme" :projects="projects" />
        <AboutSection :isDarkTheme="isDarkTheme" />
        <UpcomingProjects 
          :isDarkTheme="isDarkTheme" 
          :upcomingProjects="upcomingProjects" 
          @donate="showDonationSystem" 
        />
        <DonationSystem 
          v-if="showDonation" 
          :isDarkTheme="isDarkTheme" 
          :projectId="selectedProjectId"
          @close="closeDonationSystem" 
        />
      </main>
      
      <PageFooter :ngoName="ngoName" :isDarkTheme="isDarkTheme" />
      
      <BottomNavigation class="mobile-only" @open-donate-modal="openDonateModal" />
      
      <DonationModal 
        v-if="showDonateModal" 
        :isDarkTheme="isDarkTheme" 
        @close="closeDonateModal"
      />
    </template>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import AppHeader from '@/components/AppHeader.vue'
import HeroSection from '@/components/HeroSection.vue'
import AboutSection from '@/components/AboutSection.vue'
import ProjectsSlider from '@/components/ProjectsSlider.vue'
import DonationSystem from '@/components/DonationSystem.vue'
import UpcomingProjects from '@/components/UpcomingProjects.vue'
import PageFooter from '@/components/PageFooter.vue'
import BottomNavigation from '@/components/BottomNavigation.vue'
import DonationModal from '@/components/DonationModal.vue'

export default {
  name: 'HomePage',
  components: {
    AppHeader,
    HeroSection,
    AboutSection,
    ProjectsSlider,
    DonationSystem,
    UpcomingProjects,
    PageFooter,
    BottomNavigation,
    DonationModal
  },
  props: {
    isDarkTheme: {
      type: Boolean,
      required: true
    }
  },
  emits: ['toggle-theme'],
  setup() {
    const ngoName = ref('Freemyworld')
    const showDonation = ref(false)
    const showDonateModal = ref(false)
    const selectedProjectId = ref(null)
    const isLoading = ref(true)

    const showDonationSystem = (projectId) => {
      selectedProjectId.value = projectId
      showDonation.value = true
    }

    const closeDonationSystem = () => {
      showDonation.value = false
      selectedProjectId.value = null
    }

    const openDonateModal = () => {
      showDonateModal.value = true
    }

    const closeDonateModal = () => {
      showDonateModal.value = false
    }

    onMounted(async () => {
      // Simulate loading time or fetch necessary data
      await new Promise(resolve => setTimeout(resolve, 2000))
      isLoading.value = false
    })

    return {
      ngoName,
      showDonation,
      showDonateModal,
      selectedProjectId,
      isLoading,
      showDonationSystem,
      closeDonationSystem,
      openDonateModal,
      closeDonateModal
    }
  }
}
</script>

<style scoped>
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.preloader-content {
  text-align: center;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.logo-text {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--accent-color);
}

.globe {
  width: 50px;
  height: 50px;
  position: relative;
  margin: 0 10px;
  animation: rotate 4s linear infinite;
}

.globe-sphere {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(45deg, var(--accent-color), var(--bg-secondary));
  position: absolute;
}

.globe-equator, .globe-meridian {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.3);
  position: absolute;
}

.globe-equator {
  transform: rotateZ(60deg);
}

.globe-meridian {
  transform: rotateY(60deg);
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  font-size: 1.5rem;
  color: var(--text-primary);
}

.loading-text span {
  display: inline-block;
  animation: wave 1.5s infinite;
}

.loading-text span:nth-child(2) { animation-delay: 0.1s; }
.loading-text span:nth-child(3) { animation-delay: 0.2s; }
.loading-text span:nth-child(4) { animation-delay: 0.3s; }
.loading-text span:nth-child(5) { animation-delay: 0.4s; }
.loading-text span:nth-child(6) { animation-delay: 0.5s; }
.loading-text span:nth-child(7) { animation-delay: 0.6s; }

@keyframes wave {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
}
</style>