import { defineStore } from 'pinia';
import api from '@/axios/api.js';

export const useDonorManagementStore = defineStore('donorManagement', {
    state: () => ({
        donors: [],
        currentPage: 1,
        totalPages: 1,
        searchQuery: '',
        sortBy: 'name',
        sortOrder: 'asc',
        selectedDonor: null,
        donorPayments: [],
        totalDonation: 0,
        paymentCount: 0,
        loading: false,
        error: null
      }),
  actions: {
    async fetchDonors() {
      try {
        const response = await api.get('/donors', {
          params: {
            page: this.currentPage,
            search: this.searchQuery,
            sortBy: this.sortBy,
            sortOrder: this.sortOrder
          }
        });
        this.donors = response.data.donors;
        this.totalPages = response.data.totalPages;
      } catch (error) {
        console.error('Error fetching donors:', error);
      }
    },
    async fetchDonorPayments(donorId) {
        this.loading = true;
        this.error = null;
        try {
          console.log(`Fetching payments for donor ID: ${donorId}`);
          const response = await api.get(`/donors/${donorId}/payments`);
          console.log('API response:', response.data);
          
          this.selectedDonor = response.data.donor;
          this.donorPayments = response.data.payments;
          this.totalDonation = response.data.totalDonation;
          this.paymentCount = response.data.paymentCount;
          
          console.log('Donor payments fetched successfully');
          return response.data;
        } catch (error) {
          console.error('Error fetching donor payments:', error.response || error);
          this.error = error.response?.data?.error || error.message || 'Failed to fetch donor payments';
          throw error;
        } finally {
          this.loading = false;
        }
      },
    async sendEmail(donorId, subject, message) {
      try {
        await api.post(`/donors/${donorId}/email`, { subject, message });
        return true;
      } catch (error) {
        console.error('Error sending email:', error);
        return false;
      }
    },
    async sendBulkEmail(subject, message, filter) {
      try {
        const response = await api.post('/donors/bulk-email', { subject, message, filter });
        return response.data;
      } catch (error) {
        console.error('Error sending bulk email:', error);
        throw error;
      }
    },
    setSearchQuery(query) {
      this.searchQuery = query;
      this.currentPage = 1; // Reset to first page when searching
      this.fetchDonors();
    },
    setSorting(sortBy) {
      if (this.sortBy === sortBy) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortBy = sortBy;
        this.sortOrder = 'asc';
      }
      this.fetchDonors();
    },
    setPage(page) {
      this.currentPage = page;
      this.fetchDonors();
    }
  }
});