<template>
  <div class="adm-about-admin">
    <h2 class="adm-section-title">Manage About Section</h2>
    <form @submit.prevent="saveAboutSection" class="adm-form">
      <div class="adm-form-group">
        <label for="title" class="adm-form-label">Title:</label>
        <input id="title" v-model="form.title" required class="adm-form-input">
      </div>
      <div class="adm-form-group">
        <label for="short_about" class="adm-form-label">Short About:</label>
        <textarea id="short_about" v-model="form.short_about" required class="adm-form-textarea"></textarea>
      </div>
      <div class="adm-form-group">
        <label for="mission" class="adm-form-label">Mission:</label>
        <textarea id="mission" v-model="form.mission" required class="adm-form-textarea"></textarea>
      </div>
      <div class="adm-form-group">
        <label for="vision" class="adm-form-label">Vision:</label>
        <textarea id="vision" v-model="form.vision" required class="adm-form-textarea"></textarea>
      </div>
      <div class="adm-form-group">
        <label class="adm-form-label">Goals:</label>
        <div v-for="(goal, index) in form.goals" :key="'goal-' + index" class="adm-input-group">
          <input v-model="form.goals[index]" required class="adm-form-input">
          <button type="button" @click="removeGoal(index)" class="adm-btn adm-btn-danger adm-btn-icon">
            <i class="bi bi-trash"></i>
          </button>
        </div>
        <button type="button" @click="addGoal" class="adm-btn adm-btn-secondary">
          <i class="bi bi-plus-circle"></i> Add Goal
        </button>
      </div>
      <div class="adm-form-group">
        <label class="adm-form-label">Impacts:</label>
        <div v-for="(impact, index) in form.impacts" :key="'impact-' + index" class="adm-input-group">
          <input v-model="form.impacts[index]" required class="adm-form-input">
          <button type="button" @click="removeImpact(index)" class="adm-btn adm-btn-danger adm-btn-icon">
            <i class="bi bi-trash"></i>
          </button>
        </div>
        <button type="button" @click="addImpact" class="adm-btn adm-btn-secondary">
          <i class="bi bi-plus-circle"></i> Add Impact
        </button>
      </div>
      <div class="adm-form-group">
        <label class="adm-form-label">Images:</label>
        <file-pond
          ref="pond"
          :allow-multiple="true"
          accepted-file-types="image/*"
          :server="server"
          :files="processedImages"
          @updatefiles="handleFilesUpdate"
          label-idle="Drag & Drop your images or <span class='filepond--label-action'>Browse</span>"
          class="adm-filepond"
        />
      </div>

      <div class="adm-form-actions">
        <button type="submit" :disabled="aboutStore.loading" class="adm-btn adm-btn-primary">
          <i class="bi bi-save"></i> Save Changes
        </button>
      </div>
    </form>
  </div>
</template>


<script>
import { ref, onMounted, computed } from 'vue';
import { useAboutStore } from '@/stores/about';
import vueFilePond from "vue-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

const FilePond = vueFilePond(FilePondPluginImagePreview, FilePondPluginFileValidateType);

export default {
  name: 'AboutSectionAdmin',
  components: {
    FilePond
  },
  setup() {
    const aboutStore = useAboutStore();
    const form = ref({
      title: '',
      short_about: '',
      mission: '',
      vision: '',
      goals: [],
      impacts: [],
      images: []
    });

    const processedImages = computed(() => {
      return form.value.images.map(img => ({
        source: img.image_url,
        options: {
          type: 'local',
          file: {
            name: img.alt_text,
            size: 0,
            type: 'image/jpeg'
          },
          metadata: {
            poster: img.image_url
          }
        }
      }));
    });

    const server = {
      process: async (fieldName, file, metadata, load, error) => {
        try {
          const url = await aboutStore.uploadImage(file);
          load(url);
        } catch (err) {
          error('Upload failed');
        }
      },
      load: (source, load, error) => {
        fetch(source)
          .then(response => response.blob())
          .then(load)
          .catch(() => error('Failed to load image'));
      },
      remove: async (source, load, error) => {
        try {
          await aboutStore.removeImage(source);
          load();
        } catch (err) {
          error('Remove failed');
        }
      }
    };

    onMounted(async () => {
      await aboutStore.fetchAboutSection();
      form.value = { ...aboutStore.$state };
    });

    const addGoal = () => form.value.goals.push('');
    const removeGoal = (index) => form.value.goals.splice(index, 1);
    const addImpact = () => form.value.impacts.push('');
    const removeImpact = (index) => form.value.impacts.splice(index, 1);

    const handleFilesUpdate = (fileItems) => {
      form.value.images = fileItems.map(fileItem => {
        const isNewUpload = fileItem.source instanceof File || fileItem.source instanceof Blob;
        return {
          image_url: isNewUpload ? fileItem.serverId || fileItem.source : fileItem.source,
          alt_text: fileItem.filename
        };
      });
    };

    const saveAboutSection = async () => {
      try {
        await aboutStore.updateAboutSection(form.value);
        alert('About section updated successfully!');
      } catch (error) {
        console.error('Failed to update about section:', error);
        alert('Failed to update about section. Please try again.');
      }
    };

    return {
      aboutStore,
      form,
      processedImages,
      server,
      addGoal,
      removeGoal,
      addImpact,
      removeImpact,
      handleFilesUpdate,
      saveAboutSection
    };
  }
}
</script>

<style scoped>
.adm-about-admin {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--adm-spacing-md);
}

.adm-section-title {
  font-size: var(--adm-font-size-xl);
  margin-bottom: var(--adm-spacing-lg);
  color: var(--adm-primary-color);
  font-weight: var(--adm-font-weight-bold);
  text-align: center;
}

.adm-form {
  background-color: var(--adm-bg-primary);
  padding: var(--adm-spacing-lg);
  border-radius: var(--adm-border-radius);
  box-shadow: var(--adm-shadow);
}

.adm-form-group {
  margin-bottom: var(--adm-spacing-lg);
}

.adm-form-label {
  display: block;
  margin-bottom: var(--adm-spacing-xs);
  font-weight: var(--adm-font-weight-bold);
  color: var(--adm-text-color);
}

.adm-form-input,
.adm-form-textarea {
  width: 100%;
  padding: var(--adm-spacing-sm);
  border: 1px solid var(--adm-border-color);
  border-radius: var(--adm-border-radius-sm);
  font-size: var(--adm-font-size-base);
  color: var(--adm-text-color);
  background-color: var(--adm-bg-primary);
  transition: var(--adm-transition);
}

.adm-form-input:focus,
.adm-form-textarea:focus {
  outline: none;
  border-color: var(--adm-primary-color);
  box-shadow: 0 0 0 2px rgba(var(--adm-primary-color-rgb), 0.2);
}

.adm-form-textarea {
  min-height: 120px;
  resize: vertical;
}

.adm-input-group {
  display: flex;
  margin-bottom: var(--adm-spacing-sm);
}

.adm-input-group .adm-form-input {
  flex-grow: 1;
  margin-right: var(--adm-spacing-sm);
}

.adm-btn {
  padding: var(--adm-spacing-sm) var(--adm-spacing-md);
  border: none;
  border-radius: var(--adm-border-radius-sm);
  cursor: pointer;
  font-size: var(--adm-font-size-base);
  font-weight: var(--adm-font-weight-medium);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: var(--adm-transition);
  text-decoration: none;
}

.adm-btn i {
  margin-right: var(--adm-spacing-xs);
}

.adm-btn-icon {
  padding: var(--adm-spacing-sm);
  font-size: var(--adm-font-size-lg);
}

.adm-btn-icon i {
  margin-right: 0;
}

.adm-btn-primary {
  background-color: var(--adm-primary-color);
  color: var(--adm-bg-primary);
}

.adm-btn-primary:hover,
.adm-btn-primary:focus {
  background-color: var(--adm-primary-color-dark);
}

.adm-btn-secondary {
  background-color: var(--adm-secondary-color);
  color: var(--adm-text-color);
}

.adm-btn-secondary:hover,
.adm-btn-secondary:focus {
  background-color: var(--adm-secondary-color-dark);
}

.adm-btn-danger {
  background-color: var(--adm-danger-color);
  color: var(--adm-bg-primary);
}

.adm-btn-danger:hover,
.adm-btn-danger:focus {
  background-color: var(--adm-danger-color-dark);
}

.adm-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.adm-filepond {
  margin-top: var(--adm-spacing-sm);
}

.adm-form-actions {
  margin-top: var(--adm-spacing-xl);
  text-align: center;
}

/* FilePond custom styles */
:deep(.filepond--panel-root) {
  background-color: var(--adm-bg-secondary);
  border: 2px dashed var(--adm-border-color);
}

:deep(.filepond--drop-label) {
  color: var(--adm-text-color);
}

:deep(.filepond--label-action) {
  text-decoration-color: var(--adm-text-color-light);
}

:deep(.filepond--panel-root) {
  border-radius: var(--adm-border-radius);
}

/* Responsive styles */
@media (max-width: 768px) {
  .adm-about-admin {
    padding: var(--adm-spacing-sm);
  }

  .adm-form {
    padding: var(--adm-spacing-md);
  }

  .adm-section-title {
    font-size: var(--adm-font-size-lg);
  }

  .adm-input-group {
    flex-direction: column;
  }

  .adm-input-group .adm-form-input {
    margin-right: 0;
    margin-bottom: var(--adm-spacing-xs);
  }

  .adm-btn-icon {
    padding: var(--adm-spacing-xs);
  }
}
</style>