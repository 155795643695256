<template>
  <div class="adm-dashboard-summary">
    <h2 class="adm-dashboard-title">Donation Dashboard</h2>
    
    <div v-if="isDataLoaded" class="adm-dashboard-grid">
      <!-- Total Donations Card -->
      <div class="adm-dashboard-card">
        <div class="adm-dashboard-card-body">
          <h5 class="adm-dashboard-card-title">Total Donations</h5>
          <p class="adm-dashboard-card-text">{{ formatCurrency(totalDonations) }}</p>
        </div>
      </div>
      
      <!-- Total Donors Card -->
      <div class="adm-dashboard-card">
        <div class="adm-dashboard-card-body">
          <h5 class="adm-dashboard-card-title">Total Donors</h5>
          <p class="adm-dashboard-card-text">{{ totalDonors }}</p>
        </div>
      </div>
      
      <!-- Average Donation Card -->
      <div class="adm-dashboard-card">
        <div class="adm-dashboard-card-body">
          <h5 class="adm-dashboard-card-title">Average Donation</h5>
          <p class="adm-dashboard-card-text">{{ formatCurrency(averageDonation) }}</p>
        </div>
      </div>
    </div>
    
    <div v-if="isDataLoaded" class="adm-dashboard-grid adm-mt-lg adm-two-column">
      <!-- Projects Funding Progress -->
      <div class="adm-dashboard-card">
        <div class="adm-dashboard-card-body">
          <h5 class="adm-dashboard-card-title">Projects Funding Progress</h5>
          <div v-for="project in projects" :key="project.id" class="adm-mb-md">
            <h6 class="adm-dashboard-subtitle">{{ project.name }}</h6>
            <div class="adm-dashboard-progress">
              <div 
                class="adm-dashboard-progress-bar" 
                role="progressbar" 
                :style="{ width: `${calculateProgressPercentage(project.funding_received, project.funding_needed)}%` }"
                :aria-valuenow="calculateProgressPercentage(project.funding_received, project.funding_needed)"
                aria-valuemin="0" 
                aria-valuemax="100"
              >
                {{ formatCurrency(project.funding_received) }} / {{ formatCurrency(project.funding_needed) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Donation Frequency -->
      <div class="adm-dashboard-card">
        <div class="adm-dashboard-card-body">
          <h5 class="adm-dashboard-card-title">Donation Frequency</h5>
          <apexchart 
            type="pie" 
            :options="frequencyChartOptions" 
            :series="[oneTimeDonationsCount, monthlyDonationsCount]"
          ></apexchart>
        </div>
      </div>
    </div>
    
    <!-- Monthly Donation Trend -->
    <div v-if="isDataLoaded" class="adm-dashboard-grid adm-mt-lg">
      <div class="adm-dashboard-card adm-dashboard-card-wide">
        <div class="adm-dashboard-card-body">
          <h5 class="adm-dashboard-card-title">Monthly Donation Trend</h5>
          <div v-if="monthlyTrend.length >= 2">
            <apexchart 
              type="line" 
              :options="trendChartOptions" 
              :series="[{
                name: 'Donations',
                data: monthlyTrend.map(m => parseFloat(m.amount))
              }]"
            ></apexchart>
          </div>
          <div v-else-if="monthlyTrend.length === 1" class="adm-dashboard-single-month">
            <p>Data available for one month:</p>
            <p>{{ formatMonthYear(monthlyTrend[0].month) }}: {{ formatCurrency(monthlyTrend[0].amount) }}</p>
          </div>
          <div v-else class="adm-dashboard-no-data">
            <p>No monthly trend data available yet.</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isDataLoaded" class="adm-dashboard-loading">
      <div class="adm-dashboard-spinner"></div>
      <p class="adm-dashboard-loading-text">Loading dashboard data...</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useDashboardStore } from '@/stores/dashboardStore';
import { formatCurrency } from '@/utils/formatters';
import VueApexCharts from 'vue3-apexcharts';

export default {
  name: 'DashboardSummary',
  components: {
    apexchart: VueApexCharts,
  },
  setup() {
    const dashboardStore = useDashboardStore();
    const isDataLoaded = ref(false);
    const totalDonations = ref(0);
    const totalDonors = ref(0);
    const projects = ref([]);
    const oneTimeDonationsCount = ref(0);
    const monthlyDonationsCount = ref(0);
    const monthlyTrend = ref([]);

    const averageDonation = computed(() => totalDonations.value / totalDonors.value || 0);

    const frequencyChartOptions = {
      labels: ['One-time', 'Monthly'],
      colors: ['#007bff', '#28a745'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    };

    const trendChartOptions = computed(() => ({
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        width: 7,
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: monthlyTrend.value.map(m => m.month),
      },
      title: {
        text: 'Monthly Donations',
        align: 'left',
        style: {
          fontSize: "16px",
          color: '#666'
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          gradientToColors: ['#FDD835'],
          shadeIntensity: 1,
          type: 'horizontal',
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100]
        },
      },
      markers: {
        size: 4,
        colors: ["#FFA41B"],
        strokeColors: "#fff",
        strokeWidth: 2,
        hover: {
          size: 7,
        }
      },
      yaxis: {
        min: 0,
        max: Math.max(...monthlyTrend.value.map(m => parseFloat(m.amount))) * 1.2 || 100,
        title: {
          text: 'Donation Amount',
        },
      }
    }));

    const formatMonthYear = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleString('default', { month: 'long', year: 'numeric' });
    };



    const calculateProgressPercentage = (fundingReceived, fundingGoal) => {
      return (parseFloat(fundingReceived) / parseFloat(fundingGoal)) * 100;
    };

    onMounted(async () => {
      try {
        await dashboardStore.fetchDashboardData();
        
        totalDonations.value = parseFloat(dashboardStore.totalDonations);
        totalDonors.value = dashboardStore.totalDonors;
        projects.value = dashboardStore.projects;
        oneTimeDonationsCount.value = dashboardStore.oneTimeDonationsCount;
        monthlyDonationsCount.value = dashboardStore.monthlyDonationsCount;
        monthlyTrend.value = dashboardStore.monthlyTrend;

        isDataLoaded.value = true;
      } catch (error) {
        console.error('Failed to load dashboard data:', error);
        // Here you might want to set an error state and display an error message to the user
      }
    });

    return {
      isDataLoaded,
      totalDonations,
      totalDonors,
      averageDonation,
      projects,
      oneTimeDonationsCount,
      monthlyDonationsCount,
      monthlyTrend,
      frequencyChartOptions,
      trendChartOptions,
      formatCurrency,
      calculateProgressPercentage,
      formatMonthYear
    };
  }
};
</script>

<style scoped>
.adm-dashboard-summary {
  padding: var(--adm-spacing-md);
}

.adm-dashboard-title {
  font-size: var(--adm-font-size-xl);
  margin-bottom: var(--adm-spacing-lg);
  color: var(--adm-primary-color);
  font-weight: var(--adm-font-weight-bold);
  text-align: center;
}

.adm-dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: var(--adm-spacing-md);
}

.adm-dashboard-grid.adm-two-column {
  grid-template-columns: 1fr 1fr;
}

.adm-dashboard-card {
  background-color: var(--adm-bg-primary);
  border-radius: var(--adm-border-radius);
  box-shadow: var(--adm-shadow);
  transition: var(--adm-transition);
}

.adm-dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.adm-dashboard-card-wide {
  grid-column: 1 / -1;
}

.adm-dashboard-card-body {
  padding: var(--adm-spacing-md);
}

.adm-dashboard-card-title {
  font-size: var(--adm-font-size-lg);
  margin-bottom: var(--adm-spacing-sm);
  color: var(--adm-text-color);
  font-weight: var(--adm-font-weight-bold);
}

.adm-dashboard-card-text {
  font-size: var(--adm-font-size-xl);
  color: var(--adm-primary-color);
  font-weight: var(--adm-font-weight-bold);
}

.adm-dashboard-subtitle {
  font-size: var(--adm-font-size-base);
  margin-bottom: var(--adm-spacing-xs);
  color: var(--adm-text-color);
  font-weight: var(--adm-font-weight-medium);
}

.adm-dashboard-progress {
  height: 20px;
  background-color: var(--adm-bg-secondary);
  border-radius: var(--adm-border-radius-sm);
  overflow: hidden;
  margin-bottom: var(--adm-spacing-sm);
}

.adm-dashboard-progress-bar {
  background-color: var(--adm-primary-color);
  color: var(--adm-bg-primary);
  text-align: center;
  line-height: 20px;
  font-size: var(--adm-font-size-sm);
  transition: var(--adm-transition);
}

.adm-dashboard-loading {
  text-align: center;
  padding: var(--adm-spacing-lg);
}

.adm-dashboard-spinner {
  border: 4px solid var(--adm-bg-secondary);
  border-top: 4px solid var(--adm-primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: adm-dashboard-spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes adm-dashboard-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.adm-dashboard-loading-text {
  margin-top: var(--adm-spacing-sm);
  color: var(--adm-text-color);
}

.adm-dashboard-single-month,
.adm-dashboard-no-data {
  text-align: center;
  padding: var(--adm-spacing-md);
  background-color: var(--adm-bg-secondary);
  border-radius: var(--adm-border-radius);
}

.adm-dashboard-single-month p,
.adm-dashboard-no-data p {
  margin: 0;
  font-size: var(--adm-font-size-base);
}

.adm-dashboard-single-month p:last-child {
  font-weight: var(--adm-font-weight-bold);
  margin-top: var(--adm-spacing-sm);
}

.adm-mt-lg {
  margin-top: var(--adm-spacing-lg);
}

.adm-mb-md {
  margin-bottom: var(--adm-spacing-md);
}

@media (max-width: 768px) {
  .adm-dashboard-summary {
    padding: var(--adm-spacing-sm);
  }

  .adm-dashboard-title {
    font-size: var(--adm-font-size-lg);
  }

  .adm-dashboard-card-body {
    padding: var(--adm-spacing-sm);
  }

  .adm-dashboard-card-text {
    font-size: var(--adm-font-size-lg);
  }

  .adm-dashboard-grid.adm-two-column {
    grid-template-columns: 1fr;
  }
}
</style>