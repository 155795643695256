<template>
  <section id="about" class="container about-section hasMargins">
    <h2>About Us</h2>
    <div v-if="aboutStore.loading">Loading...</div>
    <div v-else-if="aboutStore.error">{{ aboutStore.error }}</div>
    <div v-else class="about-content">
      <div class="about-image-collage">
        <div v-for="(image, index) in displayedImages" :key="index" class="collage-item" @click="enlargeImage(image)">
          <img :src="image.image_url" :alt="image.alt_text">
        </div>
      </div>
      <div class="about-text">
        <h3>{{ aboutStore.title }}</h3>
        <p>{{ aboutStore.short_about }}</p>
        <div class="info-grid">
          <div class="info-box mission">
            <h4>Our Mission</h4>
            <p>{{ aboutStore.mission }}</p>
          </div>
          <div class="info-box vision">
            <h4>Our Vision</h4>
            <p>{{ aboutStore.vision }}</p>
          </div>
          <div class="info-box goals">
            <h4>Our Goals</h4>
            <ul>
              <li v-for="(goal, index) in aboutStore.goals" :key="index">{{ goal }}</li>
            </ul>
          </div>
          <div class="info-box impact">
            <h4>Our Impact</h4>
            <ul>
              <li v-for="(impact, index) in aboutStore.impacts" :key="index">{{ impact }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="enlargedImage" class="image-modal" @click="closeEnlargedImage">
      <img :src="enlargedImage.image_url" :alt="enlargedImage.alt_text">
    </div>
  </section>
</template>

<script>
import { useAboutStore } from '@/stores/about';
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';

export default {
  name: 'AboutSection',
  setup() {
    const aboutStore = useAboutStore();
    const enlargedImage = ref(null);
    const rotationInterval = ref(null);

    const displayedImages = computed(() => {
      const shuffled = [...aboutStore.images].sort(() => 0.5 - Math.random());
      return shuffled.slice(0, 12);
    });

    const startRotation = () => {
      rotationInterval.value = setInterval(() => {
        // This will trigger a re-computation of displayedImages
        aboutStore.$patch({ images: [...aboutStore.images] });
      }, 10000); // Rotate every 10 seconds
    };

    const stopRotation = () => {
      clearInterval(rotationInterval.value);
    };

    const enlargeImage = (image) => {
      enlargedImage.value = image;
      stopRotation();
    };

    const closeEnlargedImage = () => {
      enlargedImage.value = null;
      startRotation();
    };

    onMounted(async () => {
      await aboutStore.fetchAboutSection();
      startRotation();
    });

    onBeforeUnmount(() => {
      stopRotation();
    });

    return {
      aboutStore,
      displayedImages,
      enlargedImage,
      enlargeImage,
      closeEnlargedImage
    };
  }
}
</script>

  
  <style scoped>
  .about-section {
    padding: 2rem;
    background-color: var(--light-bg-secondary);
    border-radius: var(--border-radius);
    overflow: hidden;
  }
  
  .dark-theme .about-section {
    background-color: var(--dark-bg-secondary);
  }
  
  .about-section h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 3rem;
    color: var(--light-accent-color);
    position: relative;
  }
  
  .dark-theme .about-section h2 {
    color: var(--dark-accent-color);
  }
  
  .about-section h2::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: var(--light-accent-color);
    margin: 1rem auto 0;
  }
  
  .dark-theme .about-section h2::after {
    background-color: var(--dark-accent-color);
  }
  
  .about-content {
    display: flex;
    align-items: stretch;
    gap: 4rem;
  }
  
  .about-image-collage {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 1rem;
    max-width: 50%;
  }
  
  .collage-item {
    overflow: hidden;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform var(--transition-speed);
    cursor: pointer;
  }
  
  .collage-item:hover {
    transform: scale(1.05);
  }
  
  .collage-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .image-modal img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
  
  @media (max-width: 1200px) {
    .about-image-collage {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
      max-width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .about-image-collage {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(6, 1fr);
    }
  }
  .about-text {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .about-text h3 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: var(--light-accent-color);
  }
  
  .dark-theme .about-text h3 {
    color: var(--dark-accent-color);
  }
  
  .about-text > p {
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 2rem;
    color: var(--light-text-secondary);
  }
  
  .dark-theme .about-text > p {
    color: var(--dark-text-secondary);
  }
  
  .info-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    flex-grow: 1;
  }
  
  .info-box {
    background-color: var(--light-bg-primary);
    border-radius: var(--border-radius);
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: transform var(--transition-speed);
  }
  
  .dark-theme .info-box {
    background-color: var(--dark-bg-primary);
  }
  
  .info-box:hover {
    transform: translateY(-5px);
  }
  
  .info-box h4 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    color: var(--light-accent-color);
  }
  
  .dark-theme .info-box h4 {
    color: var(--dark-accent-color);
  }
  
  .info-box p, .info-box li {
    font-size: 1rem;
    line-height: 1.6;
    color: var(--light-text-secondary);
  }
  
  .dark-theme .info-box p, .dark-theme .info-box li {
    color: var(--dark-text-secondary);
  }
  
  .info-box ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .info-box li {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }
  
  .info-box li::before {
    content: '✓';
    color: var(--light-accent-color);
    margin-right: 0.5rem;
    font-weight: bold;
  }
  
  .dark-theme .info-box li::before {
    color: var(--dark-accent-color);
  }
  
  @media (max-width: 1200px) {
    .about-content {
      flex-direction: column;
      gap: 2rem;
    }
  
    .about-image-collage {
      max-width: 100%;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .about-section h2 {
      font-size: 2rem;
    }
  
    .about-text h3 {
      font-size: 1.8rem;
    }
  
    .about-text > p {
      font-size: 1rem;
    }
  
    .info-box h4 {
      font-size: 1.2rem;
    }
  
    .info-box p, .info-box li {
      font-size: 0.9rem;
    }
  
    .info-grid {
      grid-template-columns: 1fr;
    }
  
    .about-image-collage {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }
  }
  </style>