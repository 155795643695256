<template>
  <div class="adm-about-admin adm-projects">
    <h2 class="adm-section-title">Manage Projects</h2>
    <button @click="showAddForm = true" class="adm-btn adm-btn-primary adm-mb-md">
      <i class="bi bi-plus-circle"></i> Add New Project
    </button>

    <div v-if="showAddForm" class="adm-form-container">
      <h3 class="adm-form-title">{{ editingProject ? 'Edit Project' : 'Add New Project' }}</h3>
      <form @submit.prevent="saveProject" class="adm-form">
        <div class="adm-form-group">
          <label for="project-name" class="adm-form-label">Project Name:</label>
          <input id="project-name" v-model="currentProject.name" placeholder="Project Name" required class="adm-form-input">
        </div>
        <div class="adm-form-group">
          <label for="short-description" class="adm-form-label">Short Description:</label>
          <textarea id="short-description" v-model="currentProject.short_description" placeholder="Short Description" required class="adm-form-textarea"></textarea>
        </div>
        <div class="adm-form-group">
          <label for="long-description" class="adm-form-label">Long Description:</label>
          <textarea id="long-description" v-model="currentProject.long_description" placeholder="Long Description" required class="adm-form-textarea"></textarea>
        </div>
        <div class="adm-form-group">
          <label for="future-goals" class="adm-form-label">Future Goals:</label>
          <textarea id="future-goals" v-model="currentProject.future_goals" placeholder="Future Goals" required class="adm-form-textarea"></textarea>
        </div>
        <div class="adm-form-group">
          <label for="image-url" class="adm-form-label">Image URL:</label>
          <input id="image-url" v-model="currentProject.image_url" placeholder="Image URL" required class="adm-form-input">
        </div>
        <div class="adm-form-group">
          <label for="alt-text" class="adm-form-label">Image Alt Text:</label>
          <input id="alt-text" v-model="currentProject.alt_text" placeholder="Image Alt Text" required class="adm-form-input">
        </div>
        <div class="adm-form-group">
          <label class="adm-form-label">Achievements:</label>
          <div v-for="(achievement, index) in currentProject.achievements" :key="index" class="adm-input-group">
            <input v-model="currentProject.achievements[index]" placeholder="Achievement" class="adm-form-input">
            <button type="button" @click="removeAchievement(index)" class="adm-btn adm-btn-danger adm-btn-icon">
              <i class="bi bi-trash"></i>
            </button>
          </div>
          <button type="button" @click="addAchievement" class="adm-btn adm-btn-secondary adm-mt-sm">
            <i class="bi bi-plus-circle"></i> Add Achievement
          </button>
        </div>
        <div class="adm-form-actions">
          <button type="submit" class="adm-btn adm-btn-primary">
            <i class="bi" :class="editingProject ? 'bi-pencil-square' : 'bi-save'"></i>
            {{ editingProject ? 'Update' : 'Save' }} Project
          </button>
          <button type="button" @click="cancelEdit" class="adm-btn adm-btn-secondary">
            <i class="bi bi-x-circle"></i> Cancel
          </button>
        </div>
      </form>
    </div>

    <div class="adm-table-responsive">
      <table class="adm-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Short Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="project in projectStore.projects" :key="project.id">
            <td>{{ project.name }}</td>
            <td>{{ project.short_description }}</td>
            <td>
              <button @click="editProject(project)" class="adm-btn adm-btn-secondary adm-btn-sm">
                <i class="bi bi-pencil"></i>
              </button>
              <button @click="deleteProject(project.id)" class="adm-btn adm-btn-danger adm-btn-sm">
                <i class="bi bi-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { useProjectStore } from '@/stores/projectSliderStore';

export default {
  name: 'AdminProjects',
  setup() {
    const projectStore = useProjectStore();
    return { projectStore };
  },
  data() {
    return {
      showAddForm: false,
      editingProject: null,
      currentProject: this.getEmptyProject()
    };
  },
  methods: {
    getEmptyProject() {
      return {
        name: '',
        short_description: '',
        long_description: '',
        future_goals: '',
        image_url: '',
        alt_text: '',
        achievements: []
      };
    },
    async saveProject() {
      if (this.editingProject) {
        await this.projectStore.updateProject({ ...this.currentProject, id: this.editingProject.id });
      } else {
        await this.projectStore.addProject(this.currentProject);
      }
      this.cancelEdit();
    },
    editProject(project) {
      this.editingProject = project;
      this.currentProject = { ...project };
      this.showAddForm = true;
    },
    async deleteProject(projectId) {
      if (confirm('Are you sure you want to delete this project?')) {
        await this.projectStore.deleteProject(projectId);
      }
    },
    cancelEdit() {
      this.editingProject = null;
      this.currentProject = this.getEmptyProject();
      this.showAddForm = false;
    },
    addAchievement() {
      this.currentProject.achievements.push('');
    },
    removeAchievement(index) {
      this.currentProject.achievements.splice(index, 1);
    }
  },
  mounted() {
    this.projectStore.fetchProjects();
  }
};
</script>

<style scoped>
.adm-projects {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--adm-spacing-md);
}

.adm-section-title {
  font-size: var(--adm-font-size-xl);
  margin-bottom: var(--adm-spacing-lg);
  color: var(--adm-primary-color);
  font-weight: var(--adm-font-weight-bold);
  text-align: center;
}

.adm-form-container {
  background-color: var(--adm-bg-primary);
  padding: var(--adm-spacing-lg);
  border-radius: var(--adm-border-radius);
  box-shadow: var(--adm-shadow);
  margin-bottom: var(--adm-spacing-lg);
}

.adm-form-title {
  font-size: var(--adm-font-size-lg);
  margin-bottom: var(--adm-spacing-md);
  color: var(--adm-text-color);
  font-weight: var(--adm-font-weight-bold);
}

.adm-form {
  display: grid;
  gap: var(--adm-spacing-md);
}

.adm-form-group {
  display: flex;
  flex-direction: column;
}

.adm-form-label {
  margin-bottom: var(--adm-spacing-xs);
  font-weight: var(--adm-font-weight-medium);
  color: var(--adm-text-color);
}

.adm-form-input,
.adm-form-textarea {
  width: 100%;
  padding: var(--adm-spacing-sm);
  border: 1px solid var(--adm-border-color);
  border-radius: var(--adm-border-radius-sm);
  font-size: var(--adm-font-size-base);
  color: var(--adm-text-color);
  background-color: var(--adm-bg-primary);
  transition: var(--adm-transition);
}

.adm-form-input:focus,
.adm-form-textarea:focus {
  outline: none;
  border-color: var(--adm-primary-color);
  box-shadow: 0 0 0 2px rgba(var(--adm-primary-color-rgb), 0.2);
}

.adm-form-textarea {
  min-height: 100px;
  resize: vertical;
}

.adm-input-group {
  display: flex;
  margin-bottom: var(--adm-spacing-sm);
}

.adm-input-group .adm-form-input {
  flex-grow: 1;
  margin-right: var(--adm-spacing-sm);
}

.adm-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: var(--adm-spacing-sm);
}

.adm-btn {
  padding: var(--adm-spacing-sm) var(--adm-spacing-md);
  border: none;
  border-radius: var(--adm-border-radius-sm);
  cursor: pointer;
  font-size: var(--adm-font-size-base);
  font-weight: var(--adm-font-weight-medium);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: var(--adm-transition);
}

.adm-btn i {
  margin-right: var(--adm-spacing-xs);
}

.adm-btn-primary {
  background-color: var(--adm-primary-color);
  color: var(--adm-bg-primary);
}

.adm-btn-primary:hover,
.adm-btn-primary:focus {
  background-color: var(--adm-primary-color-dark);
}

.adm-btn-secondary {
  background-color: var(--adm-secondary-color);
  color: var(--adm-bg-primary);
}

.adm-btn-secondary:hover,
.adm-btn-secondary:focus {
  background-color: var(--adm-secondary-color-dark);
}

.adm-btn-danger {
  background-color: var(--adm-danger-color);
  color: var(--adm-bg-primary);
}

.adm-btn-danger:hover,
.adm-btn-danger:focus {
  background-color: var(--adm-danger-color-dark);
}

.adm-btn-sm {
  padding: var(--adm-spacing-xs) var(--adm-spacing-sm);
  font-size: var(--adm-font-size-sm);
}

.adm-btn-icon {
  padding: var(--adm-spacing-sm);
}

.adm-table-responsive {
  overflow-x: auto;
}

.adm-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: var(--adm-spacing-lg);
}

.adm-table th,
.adm-table td {
  padding: var(--adm-spacing-sm);
  text-align: left;
  border-bottom: 1px solid var(--adm-border-color);
}

.adm-table th {
  background-color: var(--adm-bg-secondary);
  font-weight: var(--adm-font-weight-bold);
  color: var(--adm-text-color);
}

.adm-table tr:hover {
  background-color: var(--adm-bg-secondary);
}

.adm-mb-md {
  margin-bottom: var(--adm-spacing-md);
}

.adm-mt-sm {
  margin-top: var(--adm-spacing-sm);
}

@media (max-width: 768px) {
  .adm-form-actions {
    flex-direction: column;
  }

  .adm-btn {
    width: 100%;
    margin-bottom: var(--adm-spacing-sm);
  }

  .adm-table th,
  .adm-table td {
    padding: var(--adm-spacing-xs);
  }
}
</style>