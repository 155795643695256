import { defineStore } from 'pinia';
import api from '@/axios/api.js';
import { encryptData, decryptData } from '@/utils/cryptoUtil'; // Import the utility functions

export const useAuthStore = defineStore('users', {
  state: () => ({
    isLoading: false,
    error: '',
    user: [],
    token: null,
    refreshToken: null,
  }),

  getters: {
    isAuthenticated: (state) => !!state.token,
  },

  actions: {
    clearError() {
      this.error = '';
    },

    setAccessToken(token) {
      this.token = token;
      localStorage.setItem('token', token);
    },

    setRefreshToken(refreshToken) {
      this.refreshToken = refreshToken;
      localStorage.setItem('refreshToken', refreshToken);
    },

    setTokens(token, refreshToken) {
      this.setAccessToken(token);
      this.setRefreshToken(refreshToken);
    },

    async login(email, password) {
      this.isLoading = true;
      this.clearError();
    
      try {
        const response = await api.post('/login', { email, password });
        this.user = response.data.user;
        const encryptedUser = encryptData(this.user); // Encrypt user data
        localStorage.setItem('user', encryptedUser); // Store encrypted user data
        this.setTokens(response.data.token, response.data.refreshToken);
        await this.fetchUserInfo();
      } catch (error) {
        this.handleError(error, 'Login failed');
      } finally {
        this.isLoading = false;
      }
    },

    async register(name, email, password) {
      this.isLoading = true;
      this.clearError();
      try {
        const response = await api.post('/register', { name, email, password });
        this.user = response.data.user;
        this.setTokens(response.data.token, response.data.refreshToken);
      } catch (error) {
        this.handleError(error, 'Registration failed');
      } finally {
        this.isLoading = false;
      }
    },

    async forgotPassword(email) {
      this.isLoading = true;
      this.clearError();
      try {
        await api.post('/forgot-password', { email });
      } catch (error) {
        this.handleError(error, 'Password reset failed');
      } finally {
        this.isLoading = false;
      }
    },

    async fetchUserInfo() {
      if (!this.token) return;

      this.isLoading = true;
      this.clearError();

      try {
        const response = await api.get('/user');
        if (response.data.success) {
          this.user = response.data.user;
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        this.handleError(error, 'Failed to fetch user info');
      } finally {
        this.isLoading = false;
      }
    },

    async refreshToken() {
      try {
        const response = await api.post('/refresh-token', {
          refreshToken: this.refreshToken
        });
        const { token, refreshToken } = response.data;
        this.setTokens(token, refreshToken);
        return token;
      } catch (error) {
        console.error('Failed to refresh token:', error);
        this.logout();
        throw error;
      }
    },

    async updateProfilePicture(imageUrl) {
      this.isLoading = true;
      this.clearError();

      try {
        const response = await api.put('/user/profile-picture', { image: imageUrl });
        if (response.data.success) {
          this.user = { ...this.user, image: imageUrl };
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        this.handleError(error, 'Failed to update profile picture');
      } finally {
        this.isLoading = false;
      }
    },

    async updateUserProfile(userData) {
      this.isLoading = true;
      this.clearError();

      try {
        const response = await api.put('/user/profile', userData);
        if (response.data.success) {
          this.user = response.data.user;
        } else {
          throw new Error(response.data.message || 'Failed to update user profile');
        }
      } catch (error) {
        this.handleError(error, 'An error occurred while updating the profile');
        throw error;
      } finally {
        this.isLoading = false;
      }
    },

    logout() {
      this.user = null;
      this.token = null;
      this.refreshToken = null;
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
    },

    loadUserFromLocalStorage() {
      const encryptedUser = localStorage.getItem('user');
      if (encryptedUser) {
        this.user = decryptData(encryptedUser); // Decrypt user data
      }
    },
    
    initializeAuth() {
      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');
      if (token && refreshToken) {
        this.setTokens(token, refreshToken);
        this.loadUserFromLocalStorage(); // Load user data from local storage
      }
    },

    handleFailedRefresh() {
      this.logout();
    },

    handleError(error, defaultMessage) {
      this.error = error.response?.data?.message || defaultMessage;
      console.error(this.error, error);
    },

    updateTokens(token, refreshToken) {
      this.setTokens(token, refreshToken);
    }
  }
});