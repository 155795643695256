<template>
  <header class="app-header" :class="{ 'dark-theme': isDarkTheme }">
    <div class="container">
      <a href="/" class="logo">{{ ngoName }}</a>
      <nav id="main-nav" class="desktop-nav">
        <ul>
          <li><a href="#about">About Us</a></li>
          <li><a href="#projects">Our Projects</a></li>
          <li class="upcoming"><a href="#upcoming">Funding Needed <i class="bi bi-hearts"></i></a></li>
          <li>
            <a href="#" @click.prevent="$emit('open-donate-modal')" class="donate-link">
              Donate
              <span class="donate-badge">Give Now</span>
            </a>
          </li>
        </ul>
      </nav>
      <button @click="$emit('toggle-theme')" class="theme-toggle" aria-label="Toggle theme">
        <span class="sr-only">{{ isDarkTheme ? 'Switch to light theme' : 'Switch to dark theme' }}</span>
        <i :class="isDarkTheme ? 'bi-sun' : 'bi-moon'"></i>
      </button>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  props: {
    ngoName: {
      type: String,
      required: true
    },
    isDarkTheme: {
      type: Boolean,
      required: true
    }
  },
  emits: ['toggle-theme', 'open-donate-modal']
};
</script>

<style scoped>

.app-header {
  --header-height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: var(--light-bg-secondary);
  color: var(--light-text-primary);
  transition: background-color var(--transition-speed), color var(--transition-speed);
}

.app-header.dark-theme {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
}


.donate-link {
  position: relative;
}

.donate-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: var(--light-accent-color);
  color: var(--light-bg-primary);
  font-size: 0.7rem;
  padding: 2px 6px;
  border-radius: 12px;
  font-weight: bold;
  transition: background-color var(--transition-speed);
}

.dark-theme .donate-badge {
  background-color: var(--dark-accent-color);
  color: var(--dark-bg-primary);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.upcoming a{
  color: green !important;
  font-weight: 800;
  font-style: normal;
}
.logo {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--light-accent-color);
  text-decoration: none;
  transition: color var(--transition-speed);
}

.dark-theme .logo {
  color: var(--dark-accent-color);
}

.logo:hover,
.logo:focus {
  color: var(--light-accent-color);
}

.dark-theme .logo:hover,
.dark-theme .logo:focus {
  color: var(--dark-accent-color);
}

.desktop-nav {
  display: none;
}

.theme-toggle {
  background: none;
  border: none;
  color: var(--light-text-primary);
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: color var(--transition-speed);
}

.dark-theme .theme-toggle {
  color: var(--dark-text-primary);
}

.theme-toggle:hover,
.theme-toggle:focus {
  color: var(--light-accent-color);
}

.dark-theme .theme-toggle:hover,
.dark-theme .theme-toggle:focus {
  color: var(--dark-accent-color);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

@media (min-width: 768px) {
  .container {
    padding: 0 2rem;
  }

  .desktop-nav {
    display: block;
  }

  .desktop-nav ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .desktop-nav li {
    margin: 0 0.5rem;
  }

  .desktop-nav a {
    color: var(--light-text-primary);
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: color var(--transition-speed);
  }

  .dark-theme .desktop-nav a {
    color: var(--dark-text-primary);
  }

  .desktop-nav a:hover,
  .desktop-nav a:focus {
    color: var(--light-accent-color);
  }

  .dark-theme .desktop-nav a:hover,
  .dark-theme .desktop-nav a:focus {
    color: var(--dark-accent-color);
  }
}

@media (prefers-reduced-motion: reduce) {
  .app-header,
  .logo,
  .desktop-nav a,
  .theme-toggle {
    transition: none;
  }
}
</style>